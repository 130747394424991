export const ADD_WATCH_LIST_ITEMS = 'ADD_WATCH_LIST_ITEMS'
export const ADD_WATCH_LIST_ARR_ITEMS = 'ADD_WATCH_LIST_ARR_ITEMS'
export const CHANGE_WATCH_LIST_TAB = 'CHANGE_WATCH_LIST_TAB'
export const DELETE_WATCH_LIST_TAB = 'DELETE_WATCH_LIST_TAB'
export const ADD_WATCH_LIST_TAB = 'ADD_WATCH_LIST_TAB'
export const CHANGE_TAB_NAME = 'CHANGE_TAB_NAME'
export const CLEAR_WATCH_LIST_ITEMS = 'CLEAR_WATCH_LIST_ITEMS'
export const SELECT_TAB = ''
export const SET_LATEST_ACTIVE_TAB = 'SET_LATEST_ACTIVE_TAB'
export const SET_WATCHLIST_LOADING = 'SET_WATCHLIST_LOADING'
export const CLEAR_WATCHLIST_LOADING = 'CLEAR_WATCHLIST_LOADING'
export const UPDATE_WATCHLIST_ICON = 'UPDATE_WATCHLIST_ICON'
export const SET_BUILD_YOUR_OWN = 'SET_BUILD_YOUR_OWN'
export const INITIALIZE_WATCH_LIST_TAB = 'INITIALIZE_WATCH_LIST_TAB'
export const DELETE_ALL_WATCH_LIST_TABS = 'DELETE_ALL_WATCH_LIST_TABS'

export const addWatchListItemsAction = payload => {
  return {
    type: ADD_WATCH_LIST_ITEMS,
    payload,
  }
}

export const setWatchlistLoading = () => ({ type: SET_WATCHLIST_LOADING })
export const clearWatchlistLoading = () => ({ type: CLEAR_WATCHLIST_LOADING })

export const addWatchListItemsArrAction = payload => {
  return {
    type: ADD_WATCH_LIST_ARR_ITEMS,
    payload,
  }
}

export const changeActiveWatchListAction = payload => {
  return {
    type: CHANGE_WATCH_LIST_TAB,
    payload,
  }
}

export const deleteWatchListTabAction = payload => {
  return {
    type: DELETE_WATCH_LIST_TAB,
    payload,
  }
}

export const addWatchListTabAction = payload => {
  return {
    type: ADD_WATCH_LIST_TAB,
    payload,
  }
}

export const renameWatchListTabAction = payload => {
  return {
    type: CHANGE_TAB_NAME,
    payload,
  }
}

export const updateWatchListIconAction = payload => ({
  type: UPDATE_WATCHLIST_ICON,
  payload,
})

export const clearWatchListItemsAction = payload => {
  return {
    type: CLEAR_WATCH_LIST_ITEMS,
    payload,
  }
}

export const setLatestActiveTab = tabId => ({
  type: SET_LATEST_ACTIVE_TAB,
  payload: tabId,
})

export const setBuildYourOwnAction = (tabId, buildYourOwn) => {
  return {
    type: SET_BUILD_YOUR_OWN,
    payload: { tabId, buildYourOwn },
  }
}

export const initializeWatchListTabAction = payload => {
  return {
    type: INITIALIZE_WATCH_LIST_TAB,
    payload,
  }
}

export const deleteAllWatchListTabsAction = () => ({
  type: DELETE_ALL_WATCH_LIST_TABS,
})
