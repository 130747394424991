// Action Types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'

// Action Creators
export const setAuthenticated = () => ({
  type: SET_AUTHENTICATED,
})

export const setUnauthenticated = () => ({
  type: SET_UNAUTHENTICATED,
})
