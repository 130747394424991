import Router from 'next/router'
import queryString from 'query-string'

export const updateUrlWithNewSearchParameter = (param, value) => {
  if (typeof window !== 'undefined') {
    if (value === 'reset') {
      return Router.push(``, undefined, { shallow: true })
    } else {
      const prevParam = Router.query

      const newUrlObject = {
        ...prevParam,
        [param]: value,
      }

      const newSearchString = queryString.stringify(newUrlObject)

      Router.push(`?${decodeURI(newSearchString)}`, undefined, {
        shallow: true,
      })
    }
  }
}

export const deleteUrlSearchParameter = param => {
  if (typeof window !== 'undefined') {
    const prevParam = Router.query
    delete prevParam[param]

    const newSearchString = queryString.stringify(prevParam)

    Router.push(`?${decodeURI(newSearchString)}`, undefined, { shallow: true })
  }
}

export const updateUrlWithArrayParams = params => {
  if (typeof window !== 'undefined') {
    const prevParam = Router.query

    const newSearchString = params.map(param => {
      const pa = {
        ...prevParam,
        [param.param]: `[${param.value}]`,
      }
      return queryString.stringify(pa)
    })

    Router.push(
      `?${decodeURI(newSearchString).replace(/,/g, '&')}`,
      undefined,
      { shallow: true }
    )
  }
}
