import { useRef } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import { useListBox, useOption } from '@react-aria/listbox'
import { ListBoxGroup } from './ListBoxGroup'
import styles from './styles.module.scss'

export const ListBox = props => {
  const ref = useRef()
  const { listBoxRef = ref, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)

  return (
    <ul {...listBoxProps} ref={listBoxRef} className={styles.ul}>
      {[...state.collection].map(item =>
        item.type === 'section' ? (
          <ListBoxGroup key={item.key} item={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        )
      )}
    </ul>
  )
}

const Option = ({ item, state }) => {
  const ref = useRef()
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  )

  return (
    <li
      {...optionProps}
      ref={ref}
      className={clsx(
        styles.option,
        { [styles.selected]: isSelected },
        { [styles.focused]: isFocused },
        { [styles.disabled]: isDisabled }
      )}
    >
      {item.value.icon && (
        <div className={styles.icon}>
          <Image
            src={item.value.icon}
            alt={item.value.label}
            width={24}
            height={24}
          />
        </div>
      )}
      {item.rendered}
    </li>
  )
}
