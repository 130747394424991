import styles from './styles.module.scss'
import React from 'react'
import { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Md5 } from 'ts-md5'
import clsx from 'clsx'
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword'
import { getUserDetails } from 'src/services/userServices'
import { useSelector } from 'react-redux'
import { selectAvatarUrl } from 'src/redux/selectors/avatar'
import useWidth from 'src/hooks/useWidth'

export const ProfileHeader = ({ userInfo }) => {
  const [userDetails, setUserDetails] = useState(null)
  const { email } = userInfo
  const emailHash = email ? Md5.hashStr(email) : null
  const defaultImage = 'identicon'
  const size = 32
  const avatarUrlRedux = useSelector(selectAvatarUrl)
  const [loading, setLoading] = useState(true)
  const { isMobile } = useWidth()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserDetails()
        setUserDetails(data)
      } catch (error) {
        console.error('Error fetching user details:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleLogout = async () => {
    await signOut()
    window.location.reload()
  }
  const url = `https://www.gravatar.com/avatar/${emailHash}?d=${defaultImage}&s=${size}`

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}></div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {isMobile ? (
        <div className={styles.mobileContent}>
          <Link href={'/profile'} passHref>
            <Image
              className={clsx(styles.dropdownParent)}
              src={
                avatarUrlRedux
                  ? avatarUrlRedux
                  : userDetails &&
                    userDetails.settings &&
                    userDetails.settings.avatarUrl
                  ? userDetails.settings.avatarUrl
                  : url
              }
              alt='profile'
              width={size}
              height={size}
            />
          </Link>
        </div>
      ) : (
        <div className={styles.desktopContent}>
          <div className={styles.dropdown}>
            <div className={styles.dropdownTrigger}>
              <Link href={'/profile'} passHref>
                <Image
                  className={clsx(styles.dropdownParent)}
                  src={
                    avatarUrlRedux
                      ? avatarUrlRedux
                      : userDetails &&
                        userDetails.settings &&
                        userDetails.settings.avatarUrl
                      ? userDetails.settings.avatarUrl
                      : url
                  }
                  alt='profile'
                  width={size}
                  height={size}
                />
              </Link>
              <span className={styles.email}>{email}</span>
            </div>
            <div className={styles.dropdownContent}>
              <Link href={'/profile'}>
                <a className={styles.dropdownItem}>Profile</a>
              </Link>
              <div className={styles.separator} />

              <span
                onClick={() => handleLogout()}
                className={styles.dropdownItemLogout}
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
