import EventEmitter from 'wolfy87-eventemitter'

class EventMaster {
  constructor() {
    this.ee = new EventEmitter()
  }

  addListener(...args) {
    this.ee.addListener(...args)
  }

  removeListener(...args) {
    this.ee.removeListener(...args)
  }

  emit(...args) {
    this.ee.emitEvent(...args)
  }
}

export default new EventMaster()
