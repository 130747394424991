import numeral from 'numeral'
import isNil from 'lodash/isNil'
const MILLION = 1000 * 1000
const BILLION = 1000 * 1000 * 1000
export const formatCurrency2CompactString = (number, { fixed } = {}) => {
  const formated = +number

  if (formated < 1) {
    if (fixed == 0) {
      return '<1'
    }
    return fixed
      ? (formated.toPrecision() + 0).fixed(fixed)
      : formated.toPrecision()
  } else {
    return numeral(formated).format(fixed < 1 && formated >= 10 ? '0a' : '0.0a')
  }
}

export const formatCurrency2String = (
  number,
  int = false,
  format = '0,0[.]00'
) => {
  if (isNil(number) || Number(number) === 0) return '?'

  if (number.toString().indexOf('e') !== -1) {
    return number > 1
      ? number
      : number.toFixed(number.toString().split('e-')[1])
  }

  const absNumber = Math.abs(number)

  if (absNumber < 1e-6) {
    return numeral(number).format('0,0[.][00000000]')
  }

  if (absNumber < 10) {
    return numeral(number).format('0,0.00[0000]')
  }

  if (absNumber < 100) {
    return numeral(number).format('0,0[.][0000]')
  }

  if (absNumber < 1000) {
    return numeral(number).format('0,0[.][000]')
  }

  return numeral(number).format(int ? '0,0' : format)
}

export const formatShortValue = value => {
  if (typeof value !== 'number') return null

  if (value > 1e9) {
    return `${(value / 1e9).toFixed(2).toString()}B`
  }

  if (value > 1e6) {
    return `${(value / 1e6).toFixed(2).toString()}M`
  }

  if (value > 1e3) {
    return `${(value / 1e3).toFixed(2).toString()}K`
  }

  return value.toFixed(2).toString()
}

export const formatMarketsVolume2String = number => {
  if (isNil(number) || Number(number) === 0) return '?'

  const absNumber = Math.abs(number)

  if (absNumber < 1) {
    return numeral(number).format('0,0[.][000000]')
  }

  if (absNumber < 1000) {
    return numeral(number).format('0,0.00')
  }

  return numeral(number).format('0,0')
}

export const getValues =
  (baseField, shouldBeInt) => (currency, dataObject, formatShort) => {
    const valueField = dataObject
      ? shouldBeInt
        ? Math.round(dataObject[baseField])
        : dataObject[baseField]
      : 0

    let formattedValue = formatShort
      ? formatShortValue(valueField)
      : formatCurrency2String(valueField, shouldBeInt)

    if (baseField === 'volume') {
      formattedValue = formatMarketsVolume2String(valueField)
    }

    return wrapCurrency(formattedValue, currency)
  }

export const switchCurrency = getValues('price', false)

export const switchfloorPriceCurrency = getValues('floorPrice', false)

export const switchPriceBtc = getValues('priceBtc', false)

export const switchVolume = getValues('volume', false)

export const switchVolumeReported = getValues('volumeReported', true)

export const switchMarketCap = getValues('marketCap', true)

export const getFieldOfDataObject = (dataObject, field, mathFunction) =>
  dataObject
    ? mathFunction
      ? mathFunction(dataObject[field])
      : dataObject[field]
    : 0

const currencyToSymbol = {
  USD: '$',
  EUR: '\u20AC',
  CNY: '\u00A5',
  GBP: '\u00A3',
  JPY: '\u00A5',
  CAD: 'C$',
  BRL: 'R$',
  TRY: '₺',
  RUB: '₽',
  IRR: '\uFDFC',
  UAH: '₴',
  HKD: 'HK$',
  INR: '₹',
  KZT: '₸',
  SGD: 'S$',
}

export const wrapCurrency = (value, currency) => {
  if (!currency) return value

  const isCurrencyWithPrependedSymbol = currency && currencyToSymbol[currency]

  return `${
    isCurrencyWithPrependedSymbol ? currencyToSymbol[currency] : ''
  }${value}${!isCurrencyWithPrependedSymbol ? `\u00A0${currency}` : ''}`
}

export const formatCurrency2CompactNumber = number => {
  if (!number) return ''

  if (number < 1e-6) return Number(number.toFixed(8))
  if (number < 1) return Number(number.toFixed(6))
  return Number(number.toFixed(2))
}

export const formatSpread2String = (number, format = '0,0[.]0000') => {
  if (isNil(number)) return ''

  if (number < 0.0001) return '0.0001'

  const res = numeral(number).format(format, Math.trunc)
  return res === '0' ? '0.000' : res
}

export const formatValueWithCurrency = (value, currency, isInt, isMobile) => {
  const result = Number(value)

  if (!isMobile || result < MILLION) {
    return result
      ? wrapCurrency(
          formatCurrency2String(result, isInt, false, '0,0.00'),
          currency
        )
      : '?'
  }

  const del = value > BILLION ? BILLION : MILLION
  const order = value > BILLION ? 'B' : 'M'
  return wrapCurrency((value / del).toFixed(isInt ? 0 : 2) + order, currency)
}

export const formatGlobalChartCurrency = (value, currency, isInt) => {
  const result = Number(value)

  if (result < MILLION) {
    return result
      ? wrapCurrency(
          formatCurrency2String(result, isInt, false, '0,0.00'),
          currency
        )
      : '0'
  }

  const del = value > BILLION ? BILLION : MILLION
  const order = value > BILLION ? 'B' : 'M'
  return wrapCurrency((value / del).toFixed(isInt ? 0 : 2) + order, currency)
}
