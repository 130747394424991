import axios from 'axios'
import { API_NEWS_ARTICLES, CONFIG } from 'src/consts'
import { prepareNews } from 'src/utils/data'
import { setAllNews, getNewsError } from '../actions'

export const getLineNews =
  (url = '') =>
  async dispatch => {
    try {
      const response = await axios.get(`${url}${API_NEWS_ARTICLES}`, {
        params: {
          source: CONFIG.NEWS_SRC_STRING,
          limit: CONFIG.NEWS_REQUEST_LIMIT,
          last: true,
        },
        headers: { 'Cache-Control': 'no-cache' },
      })

      dispatch(
        setAllNews({
          lineData: prepareNews(CONFIG.NEWS_LINE_SRC, response.data),
        })
      )
    } catch (error) {
      dispatch(getNewsError(error))
    }
  }

export const getListNews = () => async dispatch => {
  try {
    const response = await axios.get(`${API_NEWS_ARTICLES}`, {
      params: {
        source: CONFIG.NEWS_SRC_STRING,
        limit: CONFIG.NEWS_REQUEST_LIMIT,
        last: true,
      },
      headers: { 'Cache-Control': 'no-cache' },
    })

    dispatch(
      setAllNews({ listData: prepareNews(CONFIG.NEWS_LIST_SRC, response.data) })
    )
  } catch (error) {
    dispatch(getNewsError(error))
  }
}
