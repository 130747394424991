import { createIntl, createIntlCache, useIntl } from 'react-intl'
import { formatTimeAgo } from './timeago'
import en from './langs/en.json'

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

const baseIntl = createIntl(
  {
    locale: 'en',
    messages: flattenMessages(en),
  },
  createIntlCache()
)

const intl = {
  ...baseIntl,
  t: (key, values?) => baseIntl.formatMessage({ id: key }, values),
  timeago: formatTimeAgo,
}

export { intl }

export const useAppIntl = useIntl as () => typeof intl
