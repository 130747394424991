import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface UnderlayProps {
  isOpen?: boolean
}

export function Underlay({ isOpen }: UnderlayProps) {
  return (
    <div
      className={clsx(styles.underlay, {
        [styles.isopen]: isOpen,
      })}
    />
  )
}
