import get from 'lodash/get'

/**
 * Maps values to human-readable format
 *
 * Mapping rules:
 *  price         <-> p
 *  priceBtc      <-> pb
 *  volume        <-> v
 *  marketCap     <-> mc
 *  change        <-> ch
 *  changeShort   <-> cs
 *  name          <-> n
 *  symbol        <-> s
 *  category      <-> ca
 *  protocol      <-> pr
 *  chart         <-> ct
 *  timestamp     <-> t
 *  slug          <-> sl
 *  marketCapRank <-> mcr
 */
export function prepareMapData(md) {
  if (!md.data) {
    return null
  }

  const combined = combinedDataReduce(md.data)

  return {
    data: combined.data,
    dict: combined.dict,
    max: mapShortDataObject(md.max),
    min: mapShortDataObject(md.min),
    global: md.global, // don't need to map
    protocols: mapNestedShortDataObjects(md.protocols),
    categories: md.categories,
    timestamp: md.timestamp,
  }
}

export function prepareExchangeData(response) {
  const combined = combinedExchangesDataReduce(response)

  return {
    data: combined.data,
    dict: combined.dict,
    timestamp: response.timestamp,
    global: response.global,
    min: convertMarketsV1Values(response, 'min'),
    max: convertMarketsV1Values(response, 'max'),
  }
}

// helpers
const mapNestedShortDataObjects = source => {
  const resultObj = {}

  for (const nextKey in source) {
    resultObj[nextKey] = {
      max: mapShortDataObject(source[nextKey].max),
      min: mapShortDataObject(source[nextKey].min),
    }
  }

  return resultObj
}

export const mapLongDataObject = source => ({
  price: source.p,
  priceBtc: source.pb,
  volume: source.v,
  marketCap: source.mc,
  change: source.ch,
  changeShort: typeof source.cs === 'undefined' ? null : source.cs,
  name: typeof source.n === 'string' ? source.n.trim() : source.n,
  symbol: source.s,
  category: !!source.ca && source.ca.length > 0 ? source.ca[0] : null,
  protocol: source.pr,
  tags: !!source.ts ? source.ts.split(',') : null,
  chart: typeof source.ct === 'undefined' ? null : source.ct,
  timestamp: typeof source.t === 'undefined' ? null : source.t,
  emission: source.em,
  slug: source.sl,
  marketCapRank: source.mcr,
})

const combinedDataReduce = (data = []) => {
  return data.reduce(
    (acc, item) => {
      const longItem = mapLongDataObject(item)
      acc.data.push(longItem)
      if (longItem.slug) {
        acc.dict[longItem.slug] = longItem
      }
      return acc
    },
    {
      data: [],
      dict: {},
    }
  )
}

const mapShortDataObject = source => ({
  price: source.p,
  priceBtc: source.pb,
  volume: source.v,
  marketCap: source.mc,
  change: source.ch,
  changeShort: typeof source.cs === 'undefined' ? null : source.cs,
})

export function convertMarketsV1Values(response, type) {
  if (!response[type])
    return {
      change: null,
      changeShort: null,
      volume: null,
      changeRepoted: null,
      volumeReported: null,
    }
  return {
    ...response[type],
    volume: type === 'min' ? 0 : response[type].volume,
    volumeReported: type === 'min' ? 0 : response[type].volumeReported,
  }
}

export function getMarketsV1Globals(response) {
  return response.result ? { ...response.data.global } : {}
}

export const getMarketsV1Timestamps = response =>
  response.result ? response.data.timestamps : []

export const combinedExchangesDataReduce = response => {
  return response.data.reduce(
    (acc, item) => {
      const longItem = mapExchangeDataObject(item)
      acc.data.push(longItem)
      if (longItem.slug) {
        acc.dict[longItem.slug] = longItem
      }
      return acc
    },
    {
      data: [],
      dict: {},
    }
  )
}

export const mapExchangeDataObject = item => ({
  ...item,
  symbol: item.name,
  //lastUpdated: item.last_updated,
  fullName: item.name, //?
})

export const processCoinsMapDataUpdate = (prevState, coins) => {
  const prevStateCoinsData = get(prevState, 'coins.data')
  const { data } = coins

  const prevDataDict = prevStateCoinsData.reduce((acc, item) => {
    acc[item.symbol] = item
    return acc
  }, {})

  const updated = data.map(newData => ({
    price: newData.price,
    priceBtc: newData.priceBtc,
    volume: newData.volume,
    marketCap: newData.marketCap,
    change: newData.change,
    changeShort: newData.changeShort,
    timestamp: newData.timestamp,
    emission: newData.emission,

    name: prevDataDict[newData.symbol].name,
    symbol: prevDataDict[newData.symbol].symbol,
    category: prevDataDict[newData.symbol].category,
    protocol: prevDataDict[newData.symbol].protocol,
    slug: prevDataDict[newData.symbol].slug,
    chart: prevDataDict[newData.symbol].chart || newData.chart,
  }))

  return {
    ...coins,
    data: updated,
  }
}
