import pick from 'lodash/pick'
import {
  blockchainOptions,
  limitOptions,
  periodOptions,
} from 'src/pages/NFT/components/NFTFilters/filtersOptions'

export const filteredNFTParams = params => {
  params = pick(params, [
    'limit',
    'period',
    'blockchain',
    'exceptions',
    // 'dependsOn',
    'blockvalue',
    'floorprice',
    'currency',
    'filter_volume_min',
    'filter_volume_max',
    'filter_market_cap_min',
    'filter_market_cap_max',
    'filter_floor_price_min',
    'filter_floor_price_max',
    'filter_number_of_owners_min',
    'filter_number_of_owners_max',
    'filter_number_of_items_min',
    'filter_number_of_items_max',
  ])
  if (
    params['blockchain'] &&
    !checkFilterExists(params['blockchain'], blockchainOptions) &&
    params['blockchain'] !== 'all'
  ) {
    delete params['blockchain']
  }
  if (params['limit'] && !checkFilterExists(params['limit'], limitOptions)) {
    delete params['limit']
  }

  if (params['period'] && !checkFilterExists(params['period'], periodOptions)) {
    delete params['period']
  }

  return params
}

export const filterNFTData = (data, params) => {
  if (
    params['blockchain'] &&
    checkFilterExists(params['blockchain'], blockchainOptions) &&
    params['blockchain'] !== 'all'
  ) {
    data = data.filter(
      item =>
        item.blockchain ===
        checkFilterExists(params['blockchain'], blockchainOptions).toUpperCase()
    )
  }

  if (params['filter_volume_min']) {
    data = data.filter(
      item =>
        item.volume > params['filter_volume_min'] &&
        item.volume < params['filter_volume_max']
    )
  }
  if (params['filter_market_cap_min']) {
    data = data.filter(
      item =>
        item.marketCap > params['filter_market_cap_min'] &&
        item.marketCap < params['filter_market_cap_max']
    )
  }
  if (params['filter_floor_price_min']) {
    data = data.filter(
      item =>
        item.volume > params['filter_floor_price_min'] &&
        item.volume < params['filter_floor_price_max']
    )
  }

  if (params['filter_number_of_owners_min']) {
    data = data.filter(
      item =>
        item.volume > params['filter_number_of_owners_min'] &&
        item.volume < params['filter_number_of_owners_max']
    )
  }
  if (params['filter_number_of_items_min']) {
    data = data.filter(
      item =>
        item.volume > params['filter_number_of_items_min'] &&
        item.volume < params['filter_number_of_items_max']
    )
  }

  if (params['exceptions']) {
    const exceptions = params['exceptions']
      ? params['exceptions'].replace(/\[|\]/gi, '').split(',')
      : []

    data = data?.filter(l => {
      if (exceptions.indexOf(l.address) <= -1) return l
    })
  }

  return data
}

export const exceptionsNFTData = (data, params) => {
  const exceptions = params['exceptions']
    ? params['exceptions'].replace(/\[|\]/gi, '').split(',')
    : []

  return data?.filter(l => {
    if (exceptions.indexOf(l.address) > -1) return l
  })
}

export const checkFilterExists = (filterValue: string, filterValues: any) => {
  return filterValues.find((filter: any) => filter.value === filterValue)?.value
}
