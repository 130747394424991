import React from 'react'
import styles from './styles.module.scss'
import Image from 'next/image'

export const UsernameSuccess = ({ onClose }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src='/assets/icons/icon-check-black.svg'
          alt='Success'
          width={36}
          height={26}
        />
      </div>
      <h2 className={styles.title}>Your username has been changed</h2>
      <a className={styles.continueButton} onClick={onClose}>
        Continue
      </a>
    </div>
  )
}
