export const LOADER_TYPES = {
  mapBoxLoader: 'mapBoxLoader',
  globalLoader: 'globalLoader',
}

export const TOOLTIP = {
  EVENTS: {
    SHOW: 'SHOW',
    HIDE: 'HIDE',
    SWITCH_HIGHLIGHTER: 'SWITCH_HIGHLIGHTER',
  },
  TYPES: {
    SINGLE: 'SINGLE',
    GROUP: 'GROUP',
  },
}

export const EXCHANGES_TABLE_TYPES = {
  OVERVIEW: 'OVERVIEW',
  SOCIALS: 'SOCIALS',
}

export const COINS_TABLE_TYPES = {
  DEFAULT: 'DEFAULT',
  WATCHLIST: 'WATCHLIST',
}

export const NFT_TABLE_TYPES = {
  DEFAULT: 'DEFAULT',
}
