import { combineReducers } from 'redux'
import coinsFilters from './coins'
import exchangesFilters from './exchanges'
import sharedFilters from './shared'

export const filters = combineReducers({
  coins: coinsFilters,
  shared: sharedFilters,
  exchanges: exchangesFilters,
})

export default filters

export * from './coins'
export * from './exchanges'
export * from './shared'
