import { AnchorHTMLAttributes } from 'react'
import { Anchor } from 'src/360ui'

type MarkdownAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>

export const MarkdownAnchor = ({ children, ...rest }: MarkdownAnchorProps) => {
  return (
    <Anchor {...rest} variant='brand' target='_blank' rel='noopener noreferrer'>
      {children}
    </Anchor>
  )
}
