import styles from './styles.module.scss'
import Link from 'next/link'

export const MenuBelowItem = ({ item, currentPath }) => {
  const isActive =
    currentPath === item.path || currentPath === item.secondaryPath

  return (
    <Link href={item.path}>
      <a
        className={`${styles.menuItem} ${
          isActive ? styles.activeMenuItem : ''
        }`}
      >
        <p className={`${styles.title} ${isActive ? styles.activeTitle : ''}`}>
          {item.title}
        </p>
      </a>
    </Link>
  )
}
