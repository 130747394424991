import { intl } from 'src/i18n'

export const TOP_LOSERS_AND_GAINERS = {
  keys: ['all', 'losers', 'gainers'],
  names: {
    all: intl.t('constants.topLoosersAndGainers.all'),
    losers: intl.t('constants.topLoosersAndGainers.losers'),
    gainers: intl.t('constants.topLoosersAndGainers.gainers'),
  },
  amountOfTopSymbols: {
    losers: 200,
    gainers: 200,
  },
}

export const EXCHANGES_TOP_LOSERS_AND_GAINERS = {
  keys: ['all', 'losers', 'gainers'],
  names: {
    all: intl.t('constants.topLoosersAndGainers.all'),
    losers: intl.t('constants.topLoosersAndGainers.losers'),
    gainers: intl.t('constants.topLoosersAndGainers.gainers'),
  },
  amountOfTopSymbols: {
    losers50: 50,
    gainers50: 50,
  },
}
