import { HYDRATE } from 'next-redux-wrapper'
import { getDefaultPeriod } from 'src/consts'
import {
  SET_EXCHANGES_TOP,
  SET_EXCHANGES_PERIODS,
  ADD_EXCHANGES_EXCEPTION_LIST,
  ADD_EXCHANGES_EXCEPTION,
  DELETE_EXCHANGES_EXCEPTION,
  RESET_EXCHANGES_ALL_EXCEPTION,
  SET_EXCHANGES_GROUP,
  SET_EXCHANGES_RANGE,
  SET_EXCHANGES_MARKETS_RANGE,
  SET_EXCHANGES_STATE,
} from 'src/redux/actions'

export const exchangesFiltersDefaults = {
  top: 'all',
  dependsOn: 'volumeReported',
  range: [0, 0],
  period: getDefaultPeriod(),
  exceptions: [],
  group: 'all',
  markets: [0, 0],
  traffic: [0, 0],
}

export const exchangesFilters = (state = exchangesFiltersDefaults, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.filters.exchanges,
      }
    case SET_EXCHANGES_TOP:
      return {
        ...state,
        top: action.payload,
      }
    case SET_EXCHANGES_PERIODS:
      return {
        ...state,
        period: {
          active: action.payload.active,
          start: action.payload.start,
          end: action.payload.end,
        },
      }
    case ADD_EXCHANGES_EXCEPTION_LIST:
      return {
        ...state,
        exceptions: [...state.exceptions, ...action.payload],
      }
    case ADD_EXCHANGES_EXCEPTION:
      return {
        ...state,
        exceptions: [...state.exceptions, action.payload],
      }
    case DELETE_EXCHANGES_EXCEPTION:
      return {
        ...state,
        exceptions: state.exceptions.filter(exc => exc !== action.payload),
      }
    case RESET_EXCHANGES_ALL_EXCEPTION:
      return {
        ...state,
        exceptions: [],
      }
    case SET_EXCHANGES_GROUP:
      return {
        ...state,
        group: action.payload,
      }
    case SET_EXCHANGES_RANGE:
      return {
        ...state,
        range: action.payload,
      }
    case SET_EXCHANGES_MARKETS_RANGE:
      return {
        ...state,
        markets: action.payload,
      }
    case SET_EXCHANGES_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}

export default exchangesFilters
