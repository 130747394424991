export default class HttpError extends Error {
  status: number

  response: any

  constructor(response, status) {
    super('Http error')
    this.name = 'HttpError'
    this.response = response
    this.status = status
  }
}
