import { useState, forwardRef, Ref } from 'react'
import ReactDOM from 'react-dom'
import { DrawerProps } from './index'
import { Provider } from '../provider'

export const DrawerOverlay = forwardRef(
  (props: DrawerProps, ref: Ref<HTMLDivElement>) => {
    const { children, isOpen, container } = props
    const [exited] = useState(!isOpen)

    const mountOverlay = isOpen || !exited
    if (!mountOverlay) {
      return null
    }

    const contents = (
      <Provider
        ref={ref}
        UNSAFE_style={{ background: 'transparent', isolation: 'isolate' }}
      >
        {children}
      </Provider>
    )

    return ReactDOM.createPortal(contents, container || document.body)
  }
)
