import numeral from 'numeral'
import isNil from 'lodash/isNil'
import {
  formatCurrency2String,
  formatShortValue,
  formatMarketsVolume2String,
} from 'src/utils/number/currency'

export const MILLION = 1000 * 1000

export const BILLION = 1000 * 1000 * 1000

export const getValues =
  (baseField, shouldBeInt) => (currency, dataObject, formatShort) => {
    const valueField = dataObject
      ? shouldBeInt
        ? Math.round(dataObject[baseField])
        : dataObject[baseField]
      : 0

    let formattedValue = formatShort
      ? formatShortValue(valueField)
      : formatCurrency2String(valueField, shouldBeInt)

    if (baseField === 'volume') {
      formattedValue = formatMarketsVolume2String(valueField)
    }

    return wrapCurrency(formattedValue, currency)
  }

export const switchCurrency = getValues('price', false)

export const switchPriceBtc = getValues('priceBtc', false)

export const switchVolume = getValues('volume', false)

export const switchVolumeReported = getValues('volumeReported', true)

export const switchMarketCap = getValues('marketCap', true)

export const getFieldOfDataObject = (dataObject, field, mathFunction) =>
  dataObject
    ? mathFunction
      ? mathFunction(dataObject[field])
      : dataObject[field]
    : 0

const currencyToSymbol = {
  USD: '$',
  EUR: '\u20AC',
  CNY: '\u00A5',
  GBP: '\u00A3',
  JPY: '\u00A5',
  CAD: 'C$',
  BRL: 'R$',
  TRY: '₺',
  RUB: '₽',
  IRR: '\uFDFC',
  UAH: '₴',
  HKD: 'HK$',
  INR: '₹',
  KZT: '₸',
  SGD: 'S$',
}

export const wrapCurrency = (value, currency) => {
  if (!currency) return value

  const isCurrencyWithPrependedSymbol = currency && currencyToSymbol[currency]

  return `${
    isCurrencyWithPrependedSymbol ? currencyToSymbol[currency] : ''
  }${value}${!isCurrencyWithPrependedSymbol ? `\u00A0${currency}` : ''}`
}

export const formatCurrency2StringOB = (value, { fullValue }) => {
  if (isNil(value)) return ''

  if (value.toString().indexOf('e') !== -1) {
    return value > 1 ? value : value.toFixed(value.toString().split('e-')[1])
  }

  const absNumber = Math.abs(value)

  if (absNumber < 1e-6) {
    return numeral(value).format('0,0[.][00000000]', Math.trunc)
  }

  if (absNumber < 1) {
    return numeral(value).format('0,0[.][000000]', Math.trunc)
  }

  if (absNumber < 10) {
    return numeral(value).format('0,0[.][00000]', Math.trunc)
  }

  if (absNumber < 100) {
    return numeral(value).format('0,0[.][0000]', Math.trunc)
  }

  if (absNumber < 1000) {
    return numeral(value).format('0,0[.][000]', Math.trunc)
  }

  if (absNumber < 10000) {
    return numeral(value).format('0,0[.][00]', Math.trunc)
  }

  if (absNumber < 100000) {
    return numeral(value).format('0,0[.][0]', Math.trunc)
  }

  if (absNumber < MILLION || fullValue) {
    return numeral(value).format('0,0', Math.trunc)
  }

  if (absNumber < BILLION) {
    return `${(value / MILLION).toFixed(2)}M`
  }

  return `${(value / BILLION).toFixed(2)}B`
}

export const formatValueWithCurrencyOB = (value, currency, isInt, fullValue) =>
  Number(value)
    ? wrapCurrency(
        formatCurrency2StringOB(Number(value), { fullValue, isInt }),
        currency
      )
    : '?'
