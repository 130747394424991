import React, { useState } from 'react'
import styles from './styles.module.scss'
import { changePassword } from 'src/services/userServices'

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError('')
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setError('Password does not match')
      return
    }

    const params = {
      oldPassword: currentPassword,
      newPassword: newPassword,
    }

    try {
      const result = await changePassword(params)
    } catch (error) {
      setError('Network error')
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label className={styles.label}>Current Password</label>
        <input
          className={styles.input}
          type='password'
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          required={true}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>New Password</label>
        <input
          className={styles.input}
          type='password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          required={true}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Confirm Password</label>
        <input
          className={styles.input}
          type='password'
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          required={true}
        />
      </div>
      <div className={styles.formGroup}>
        <button type={'submit'} className={styles.button}>
          Change Password
        </button>
      </div>
      <span className={styles.error}>{error}</span>
    </form>
  )
}
