import clsx from 'clsx'
import { ElementType, ReactNode } from 'react'
import styles from './styles.module.scss'

type TitleProps<T extends ElementType> = {
  as?: T
  children?: ReactNode
  className?: string
}

export const Title = <T extends ElementType = 'h1'>({
  as,
  children,
  className,
}: TitleProps<T>) => {
  const Component = as || 'h2'
  const titleCN = clsx(styles.title, className)

  return <Component className={titleCN}>{children}</Component>
}
