import { useRouter } from 'next/router'
import Script from 'next/script'
import { memo, useEffect } from 'react'
import { ANALITYCS_ID, TAG_MANAGER_ID } from 'src/consts/env'

const analyticsScript = ` window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${ANALITYCS_ID}');`

const tagmanagerScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${TAG_MANAGER_ID}');`

export const EventTracker = memo(() => {
  const router = useRouter()

  useEffect(() => {
    if (ANALITYCS_ID) {
      pageview(router.asPath)
    }
  }, [router.asPath])

  return (
    <>
      {ANALITYCS_ID && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${ANALITYCS_ID}`}
          />
          <Script id='analytics'>{analyticsScript}</Script>
        </>
      )}

      {TAG_MANAGER_ID && (
        <>
          <Script id='tagmanager' strategy='afterInteractive'>
            {tagmanagerScript}
          </Script>
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${TAG_MANAGER_ID}`}
              height='0'
              width='0'
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      )}
    </>
  )
})

export const pageview = url => {
  window.gtag('config', ANALITYCS_ID, { page_path: url })
}
