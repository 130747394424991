export const API_ENDPOINT = req(process.env.NEXT_PUBLIC_API_ENDPOINT)
export const BASE_URL = req(process.env.NEXT_PUBLIC_BASE_URL)
export const STRAPI_API_ENDPOINT = req(
  process.env.NEXT_PUBLIC_STRAPI_API_ENDPOINT
)

export const TAG_MANAGER_ID = process.env.NEXT_PUBLIC_TAG_MANAGER
export const ANALITYCS_ID = process.env.NEXT_PUBLIC_ANALYTICS
export const NFT_ACTIVE = bool(process.env.NEXT_PUBLIC_NFT_ACTIVE)
export const NEWS_ACTIVE = bool(process.env.NEXT_PUBLIC_NEWS_ACTIVE)
export const WALLET_ADDRESS = process.env.NEXT_PUBLIC_WALLET_ADDRESS

/** Required envar */
function req(value: string): string {
  if (!value) {
    throw new Error('Missing requied environment variable')
  }
  return value
}

/** Boolean envar */
function bool(str: string): boolean {
  return str === 'true'
}
