import axios from 'axios'
import { API_GET_EXCHANGES_MAPDATA } from 'src/consts'
import {
  refreshExchangeData,
  setExchangeData,
  setExchangeDataError,
} from '../actions'

export const getExchangeData =
  ({ url = '', slug, isRefresh = false }) =>
  async dispatch => {
    try {
      const response = await axios.get(
        `${url}${API_GET_EXCHANGES_MAPDATA}/${slug}/card?extra_info=true`
      )
      if (isRefresh) {
        dispatch(refreshExchangeData(response.data))
      } else {
        dispatch(setExchangeData(response.data))
      }
    } catch (error) {
      dispatch(setExchangeDataError(error.message))
    }
  }
