import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import Session from 'supertokens-auth-react/recipe/session'
import { EmailVerificationClaim } from 'supertokens-auth-react/recipe/emailverification'
import { setAuthenticated, setUnauthenticated } from 'src/redux/actions'
import { useState } from 'react'

const useAuth = () => {
  const [user, setUser] = useState({})
  const [emailVerified, setEmailVerified] = useState(false)
  const session = useSessionContext()
  const dispatch = useDispatch()

  const validateClaims = async () => {
    if (await Session.doesSessionExist()) {
      const validationErrors = await Session.validateClaims()
      if (validationErrors.length === 0) {
        setEmailVerified(true)
      } else {
        for (const err of validationErrors) {
          if (err.validatorId === EmailVerificationClaim.id) {
            setEmailVerified(false)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (session.loading) {
      return null
    }

    const { doesSessionExist, userId, accessTokenPayload } = session

    if (!doesSessionExist) {
      dispatch(setUnauthenticated())
    } else {
      const email = accessTokenPayload.email
      dispatch(setAuthenticated())
      setUser({ email, userId })
      validateClaims()
    }
  }, [session, dispatch])

  return { user, emailVerified }
}

export default useAuth
