import { ALL } from 'dns'

export const BASE_CURRENCY = {
  keys: [
    'USD',
    'USDT',
    'BTC',
    'ETH',
    'EOS',
    'EUR',
    'GBP',
    'CNY',
    'JPY',
    'CAD',
    'BRL',
    'TRY',
    'RUB',
    'UAH',
    'IRR',
    'HKD',
    'INR',
    'KZT',
    'SGD',
  ],
  names: {
    USD: 'USD',
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    EOS: 'EOS',
    EUR: 'EUR',
    GBP: 'GBP',
    CNY: 'CNY',
    JPY: 'JPY',
    CAD: 'CAD',
    BRL: 'BRL',
    TRY: 'TRY',
    RUB: 'RUB',
    UAH: 'UAH',
    IRR: 'IRR',
    HKD: 'HKD',
    INR: 'INR',
    KZT: 'KZT',
    SGD: 'SGD',
  },
}

export const knownCurrencies = {
  USD: true,
  USDT: true,
  BTC: true,
  ETH: true,
  EOS: true,
  EUR: true,
  CNY: true,
  GBP: true,
  JPY: true,
  CAD: true,
  BRL: true,
  TRY: true,
  RUB: true,
  IRR: true,
  UAH: true,
  HKD: true,
  INR: true,
  KZT: true,
  SGD: true,
  SEK: true,
  RON: true,
  DKK: true,
  BYN: true,
  ALL: true,
  RSD: true,
  MDL: true,
  BGN: true,
  BAM: true,
  CHF: true,
  PLN: true,
  HUF: true,
  ISK: true,
  NOK: true,
  CZK: true,
  PKR: true,
  KGS: true,
  BHD: true,
  YER: true,
  BND: true,
  SAR: true,
  SYP: true,
  MMK: true,
  LBP: true,
  LKR: true,
  IDR: true,
  KPW: true,
  IQD: true,
  QAR: true,
  TWD: true,
  PHP: true,
  LAK: true,
  UZS: true,
  BTN: true,
  KHR: true,
  JOD: true,
  TJS: true,
  MVR: true,
  AMD: true,
  ILS: true,
  AZN: true,
  KWD: true,
  AFN: true,
  OMR: true,
  NPR: true,
  THB: true,
  GEL: true,
  KRW: true,
  TMT: true,
  VND: true,
  AED: true,
  BDT: true,
  MYR: true,
  MNT: true,
  LRD: true,
  ZWL: true,
  LSL: true,
  MUR: true,
  TZS: true,
  MWK: true,
  RWF: true,
  BWP: true,
  SOS: true,
  DZD: true,
  TND: true,
  NGN: true,
  BIF: true,
  DJF: true,
  GHS: true,
  ZAR: true,
  AOA: true,
  NAD: true,
  GMD: true,
  ZMW: true,
  MGA: true,
  SDG: true,
  GNF: true,
  MAD: true,
  ERN: true,
  SSP: true,
  KMF: true,
  SZL: true,
  UGX: true,
  KES: true,
  SCR: true,
  CDF: true,
  EGP: true,
  SSL: true,
  MRU: true,
  STN: true,
  MZN: true,
  CVE: true,
  LYD: true,
  ETB: true,
  BSD: true,
  TTD: true,
  CUP: true,
  MXN: true,
  PAB: true,
  BBD: true,
  GTQ: true,
  CRC: true,
  NIO: true,
  DOP: true,
  HTG: true,
  BZD: true,
  JMD: true,
  HNL: true,
  WST: true,
  TOP: true,
  NZD: true,
  VUV: true,
  FJD: true,
  PGK: true,
  AUD: true,
  SBD: true,
  GYD: true,
  CLP: true,
  COP: true,
  SRD: true,
  UYU: true,
  BOB: true,
  ARS: true,
  PEN: true,
  VES: true,
  PYG: true,
}

export const BTCtoSAT = 100000000
