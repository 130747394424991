import { SET_AUTHENTICATED, SET_UNAUTHENTICATED } from '../actions/auth'

const initialState = {
  isAuth: false,
}

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuth: true,
      }
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        isAuth: false,
      }
    default:
      return state
  }
}
