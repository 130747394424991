import styles from './styles.module.scss'
import { getMenuItemsBelow } from '../../helpers/menuItemsBelow'
import { MenuBelowItem } from './components/MenuBelowItem'
import { useRouter } from 'next/router'
import useWidth from 'src/hooks/useWidth'
import { MenuBelowDropdown } from './components/MenuBelowDropdown'

export const MenuBelow = () => {
  const { isMobile } = useWidth()
  const router = useRouter()
  const menuItems = getMenuItemsBelow()

  return (
    <div>
      {!isMobile && (
        <div className={styles.menuDesktop}>
          {menuItems.map((item, index) => (
            <MenuBelowItem
              key={index}
              item={item}
              currentPath={router.pathname}
            />
          ))}
        </div>
      )}
      {isMobile && (
        <div className={styles.menuMobile}>
          <MenuBelowDropdown menuItems={menuItems} />
        </div>
      )}
    </div>
  )
}

export default MenuBelow
