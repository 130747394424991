import HttpError from './HttpError'

type AnyObject = Record<string, unknown | null>

export type HttpData = {
  headers?: Record<string, string>
  params?: AnyObject
  formData?: FormData
  url: string
}

type ExtendedData = HttpData & {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
}

type FetchReturn = Promise<any>

type Middlewares = {
  onRequest: {
    (data: ExtendedData): Promise<ExtendedData>
  }[]
  onResponse: {
    (response: AnyObject): Promise<AnyObject>
  }[]
  onError: { (err: HttpError): Promise<unknown> }[]
}

export class Http {
  middlewares: Middlewares = {
    onRequest: [],
    onResponse: [],
    onError: [],
  }

  get<R = any>({ params, url, ...rest }: HttpData): Promise<R> {
    const query = new URLSearchParams(stringifyJsonValues(params)).toString()
    return this.onRequestMiddlewares({
      url: params ? `${url}?${query}` : url,
      method: 'GET',
      ...rest,
    }).then(requestData => {
      return this.makeRequest(requestData)
    })
  }

  post<R>(data: HttpData): Promise<R> {
    const extendedData: ExtendedData = { ...data, method: 'POST' }

    return this.onRequestMiddlewares(extendedData).then(requestData => {
      return this.makeRequest(requestData)
    })
  }

  put<R>(data: HttpData): Promise<R> {
    const extendedData: ExtendedData = { ...data, method: 'PUT' }

    return this.onRequestMiddlewares(extendedData).then(requestData => {
      return this.makeRequest(requestData)
    })
  }

  delete<R>(data: HttpData): Promise<R> {
    const extendedData: ExtendedData = { ...data, method: 'DELETE' }

    return this.onRequestMiddlewares(extendedData).then(requestData => {
      return this.makeRequest(requestData)
    })
  }

  private makeRequest({
    method,
    params,
    headers,
    url,
    formData,
  }: ExtendedData) {
    let body
    if (formData) {
      body = formData
    } else {
      body = JSON.stringify(params)
      headers = { ...headers, 'Content-Type': 'application/json' } // Ensure content type is JSON when sending JSON
    }

    const req = fetch(url, {
      method,
      body,
      headers,
    }).then(this.processResponse)

    return this.attachMiddlewares(req)
  }

  private async processResponse(response: Response) {
    if (response.status >= 400) {
      const error = await response.json()
      throw new HttpError(error, response.status)
    }
    let res
    try {
      res = await response.json()
    } catch (e) {
      res = response
    }
    return res
  }

  private onRequestMiddlewares(data: ExtendedData): Promise<ExtendedData> {
    return this.middlewares.onRequest.reduce(
      (promise, midleware) => promise.then(midleware),
      Promise.resolve(data)
    )
  }

  private attachMiddlewares(req: Promise<AnyObject>): FetchReturn {
    let extendedReq = this.middlewares.onResponse.reduce(
      (promise, midleware) => promise.then(midleware),
      Promise.resolve(req)
    )

    extendedReq = this.middlewares.onError.reduce(
      (promise, midleware) =>
        promise.catch(err => {
          midleware(err)
          throw err
        }),
      Promise.resolve(extendedReq)
    )

    return extendedReq
  }

  addMidleware<K extends keyof Middlewares>(
    type: K,
    midleware: Middlewares[K][number]
  ) {
    // Any used because of https://github.com/microsoft/TypeScript/issues/31663
    ;(this.middlewares as any)[type].push(midleware) // eslint-disable-line @typescript-eslint/no-extra-semi
  }
}

function stringifyJsonValues(
  obj: Record<string, unknown | null> = {}
): Record<string, string> {
  return Object.entries(obj).reduce((acu, [key, value]) => {
    acu[key] = value
    return acu
  }, {})
}
