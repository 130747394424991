import { DOMRef, DOMRefValue } from '@react-types/shared'
import { RefObject, useImperativeHandle, useRef } from 'react'

export function createDOMRef<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>
): DOMRefValue<T> {
  return {
    UNSAFE_getDOMNode() {
      return ref.current
    },
  }
}

export function useDOMRef<T extends HTMLElement = HTMLElement>(
  ref: DOMRef<T>
): RefObject<T> {
  const domRef = useRef<T>(null)
  useImperativeHandle(ref, () => createDOMRef(domRef))
  return domRef
}
