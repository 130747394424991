export const buildTreeMap = (
  mapData,
  filteredMapDataArray,
  noGroups = false
) => {
  if (
    !(
      filteredMapDataArray &&
      Array.isArray(filteredMapDataArray) &&
      filteredMapDataArray.length > 0
    )
  ) {
    return null
  }

  if (noGroups) {
    return {
      name: '',
      children: filteredMapDataArray,
      minValues: { ...mapData.min },
      maxValues: { ...mapData.max },
    }
  }

  const categories = {}
  filteredMapDataArray.forEach(item => {
    const categoryName = item.category || 'others'
    let category = categories[categoryName]
    if (!category) {
      category = {
        name: mapData.categories[categoryName]?.title || categoryName,
        children: [],
        minValues: { ...mapData.min },
        maxValues: { ...mapData.max },
      }
      categories[categoryName] = category
    }
    category.children.push(item)
  })

  return {
    name: 'all',
    children: Object.values(categories),
    minValues: { ...mapData.min },
    maxValues: { ...mapData.max },
  }
}

export const buildExchangesTreeMap = (exchanges, filtered) => {
  if (!filtered) {
    return null
  }

  return {
    name: 'All Exchanges',
    children: filtered,
    minValues: exchanges?.min,
    maxValues: exchanges?.max,
  }
}
