import clsx from 'clsx'
import styles from './styles.module.scss'

export type DrawerUnderlayProps = {
  isOpen?: boolean
}

export const DrawerUnderlay = ({ isOpen }: DrawerUnderlayProps) => {
  return (
    <div
      className={clsx(styles.underlay, {
        [styles.isopen]: isOpen,
      })}
    />
  )
}
