import { createSelector } from 'reselect'
import { getFilters } from './filters'

export const getCurrentCurrencySelector = ({ filters }) =>
  filters.shared.currency

export const getSharedFilters = createSelector(getFilters, f => f.shared)

export const getSharedFiltersCurrency = createSelector(
  getSharedFilters,
  sharedFilters => sharedFilters.currency
)
