import { createSelector } from 'reselect'
import { getFilters } from './filters'

export const getExchangesFilters = createSelector(
  getFilters,
  exchangesFilters => exchangesFilters.exchanges
)

export const getExchangesDependsOn = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.dependsOn
)

export const getExchangesRange = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.range
)

export const getExchangesPeriod = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.period
)

export const getExchangesActivePeriod = createSelector(
  getExchangesPeriod,
  exchangesPeriod => exchangesPeriod.active
)

export const getExchangesExceptions = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.exceptions
)

export const getExchangesExceptionsDict = createSelector(
  getExchangesExceptions,
  e =>
    (e || []).reduce((acc, item) => {
      acc[item] = item
      return acc
    }, {})
)

export const getExchangesMarketsRange = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.markets
)

export const getExchangesGroup = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.group
)

export const getExchangesTop = createSelector(
  getExchangesFilters,
  exchangesFilters => exchangesFilters.top
)
