import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createWrapper } from 'next-redux-wrapper'
import thunkMiddleware from 'redux-thunk'
import {
  ui,
  mapdata,
  news,
  filters,
  coin,
  markets,
  exchange,
  watchlist,
  auth,
  avatar,
} from './reducers'

const devTools =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunkMiddleware)
    : composeWithDevTools(applyMiddleware(thunkMiddleware))

export const store = () =>
  createStore(
    combineReducers({
      ui,
      mapdata,
      news,
      filters,
      coin,
      markets,
      exchange,
      watchlist,
      auth,
      avatar,
    }),
    devTools
  )

export const wrapper = createWrapper(store, { debug: false })
