import store from 'store'

export const getLocalStorageItem = key => {
  try {
    return store.get(key)
  } catch (exception) {
    return false
  }
}

export const setLocalStorageItem = (key, data) => {
  try {
    store.set(key, data)
    return true
  } catch (exception) {
    return false
  }
}

export const setLocalStorageItemAsync = (key, data) =>
  new Promise(() => setLocalStorageItem(key, data))
