export const SET_COIN_DATA = 'SET_COIN_DATA'
export const SET_COIN_DATA_ERROR = 'SET_COIN_DATA_ERROR'
export const SET_COIN_RELATED_NEWS = 'SET_COIN_RELATED_NEWS'
export const REFRESH_COIN_DATA = 'REFRESH_COIN_DATA'

export const setCoinData = payload => ({
  type: SET_COIN_DATA,
  payload,
})

export const refreshCoinData = payload => ({
  type: REFRESH_COIN_DATA,
  payload,
})

export const setCoinDataError = error => ({
  type: SET_COIN_DATA_ERROR,
  error,
})

export const setCoinRelatedNews = payload => ({
  type: SET_COIN_RELATED_NEWS,
  payload,
})
