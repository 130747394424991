export const newsArticlesRoute = () => `/news`
export const newsArticleRoute = ({ slug }: { slug: string }) => `/news/${slug}`
export const newsArticleRouteCategory = (category: string) =>
  `/category/${category}`

export const glossariesRoute = () => `/glossary`
export const glossaryRoute = ({ slug }: { slug: string }) => `/glossary/${slug}`

export const listsRoute = () => `/list`
export const listRoute = ({ slug }: { slug: string }) => `/list/${slug}`

export const authorsRoute = () => `/author`
export const authorRoute = ({ slug }: { slug: string }) => `/author/${slug}`

export const listItemRoute = ({ itemSlug }: { itemSlug: string }) =>
  `/review/${itemSlug}`

export const reviewsRoute = () => `/review`

export const learnArticlesRoute = () => `/learn`
export const learnArticleRoute = ({ slug }: { slug: string }) =>
  `/learn/${slug}`
export const learnArticleRouteCategory = (category: string) =>
  `/category/${category}`
