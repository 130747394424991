import { HYDRATE } from 'next-redux-wrapper'
import {
  ADD_WATCH_LIST_ITEMS,
  CHANGE_WATCH_LIST_TAB,
  ADD_WATCH_LIST_ARR_ITEMS,
  DELETE_WATCH_LIST_TAB,
  ADD_WATCH_LIST_TAB,
  CLEAR_WATCH_LIST_ITEMS,
  SET_LATEST_ACTIVE_TAB,
  UPDATE_WATCHLIST_ICON,
  SET_BUILD_YOUR_OWN,
  INITIALIZE_WATCH_LIST_TAB,
  CHANGE_TAB_NAME,
  DELETE_ALL_WATCH_LIST_TABS,
} from 'src/redux/actions'

const initialState = {
  watchlistItems: [],
  latestActiveTab: null,
  activeTab: null,
}

export const watchlist = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.watchlist }
    case ADD_WATCH_LIST_ITEMS:
    case ADD_WATCH_LIST_ARR_ITEMS:
      return { ...state, watchlistItems: action.payload }
    case CHANGE_WATCH_LIST_TAB:
      return { ...state, activeTab: action.payload }
    case ADD_WATCH_LIST_TAB:
      return {
        ...state,
        watchlistItems: [...state.watchlistItems, action.payload],
        activeTab: action.payload.id,
        latestActiveTab: action.payload.id,
      }
    case INITIALIZE_WATCH_LIST_TAB:
      return {
        ...state,
        watchlistItems: [
          ...state.watchlistItems,
          { ...action.payload, data: [] },
        ],
        activeTab: action.payload.id,
        latestActiveTab: action.payload.id,
      }
    case SET_LATEST_ACTIVE_TAB:
      return { ...state, latestActiveTab: action.payload }
    case DELETE_WATCH_LIST_TAB:
      const remainingTabs = state.watchlistItems.filter(
        item => item.id !== action.payload
      )
      return {
        ...state,
        watchlistItems: remainingTabs,
        activeTab: remainingTabs[0]?.id || null,
      }
    case UPDATE_WATCHLIST_ICON:
      return {
        ...state,
        watchlistItems: state.watchlistItems.map(tab =>
          tab.id === action.payload.id
            ? { ...tab, iconUrl: action.payload.iconId }
            : tab
        ),
      }
    case SET_BUILD_YOUR_OWN:
      return {
        ...state,
        watchlistItems: state.watchlistItems.map(tab =>
          tab.id === action.payload.tabId
            ? { ...tab, buildYourOwn: action.payload.buildYourOwn }
            : tab
        ),
      }
    case CLEAR_WATCH_LIST_ITEMS:
      return { ...state, watchlistItems: action.payload }
    case CHANGE_TAB_NAME:
      return {
        ...state,
        watchlistItems: state.watchlistItems.map(tab =>
          tab.id === action.payload.id
            ? { ...tab, name: action.payload.name }
            : tab
        ),
      }
    case DELETE_ALL_WATCH_LIST_TABS:
      return {
        ...state,
        watchlistItems: [],
        activeTab: null,
      }
    default:
      return state
  }
}

export default watchlist
