import React, { useState } from 'react'
import styles from './styles.module.scss'
//import changeEmail from 'src/services/userServices'

export const ChangeEmail = () => {
  const [newEmail, setNewEmail] = useState('')
  // const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.title}>Enter new email address</h2>
      <p className={styles.text}>
        Enter the new email address and we will send you an email to confirm it
      </p>
      <div className={styles.formGroup}>
        <label className={styles.label}>New email address</label>
        <input
          className={styles.input}
          type='email'
          onChange={e => setNewEmail(e.target.value)}
          required={true}
        />
      </div>
      <button type={'submit'} className={styles.button}>
        Change Email
      </button>
    </form>
  )
}
