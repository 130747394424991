export const TREEMAP_ID = 'MAP_ID'

export const BLOCKS = {
  MIN_GROUP_WIDTH: 15,
  MIN_GROUP_HEIGHT: 15,

  MIN_GROUP_HEADER_WIDTH: 50,
  MIN_GROUP_HEADER_HEIGHT: 50,

  MIN_LEAF_TEXT_WIDTH: 15,
  MIN_LEAF_TEXT_HEIGHT: 15,
}
