import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

export type ContainerProps = {
  children: ReactNode
  className?: string
}

export const Container = ({ children, className }: ContainerProps) => {
  return <div className={clsx(className, styles.container)}>{children}</div>
}
