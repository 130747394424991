import { HYDRATE } from 'next-redux-wrapper'
import { ENTITY } from 'src/consts'
import {
  SET_VIEW_TYPE,
  SET_NEWS_STATE,
  TOGGLE_FULL_SCREEN,
  SET_CURRENT_ENTITY,
  SET_BANNER_VISIBILITY,
} from 'src/redux/actions'

const initialState = {
  viewType: 'map',
  fullScreen: false,
  isShare: false,
  newsState: true,
  currentEntity: ENTITY.COIN,
  bannerVisible: true,
}

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.ui,
      }
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      }
    case SET_NEWS_STATE:
      return {
        ...state,
        newsState: action.payload,
      }
    case TOGGLE_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.payload,
      }
    case SET_CURRENT_ENTITY:
      return {
        ...state,
        currentEntity: action.payload,
      }
    case SET_BANNER_VISIBILITY:
      return {
        ...state,
        bannerVisible: action.payload,
      }

    default:
      return { ...state }
  }
}

export default ui
