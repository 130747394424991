import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'

export const ViewTypeSwitcher = ({ getUrl, viewType }) => {
  const isMap = viewType === 'map'
  const icon = isMap ? 'table' : 'map'
  return (
    <Link href={getUrl(isMap ? 'table' : 'map')} prefetch={false}>
      <a className={styles.iconContainer}>
        <Image
          src={`/assets/icons/icon-${icon}.svg`}
          width='18px'
          height='18px'
          alt='Table icon'
        />
      </a>
    </Link>
  )
}

export default ViewTypeSwitcher
