export const getDirectionByChange = change => {
  if (change > 0) return 'increase'
  if (change < 0) return 'decrease'
  return 'stable'
}

export const combinedAnalytics = (event, category, action, label) => {
  pushToDataLayer({
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  })
}

export const pushToDataLayer = data => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}

export const convertBooleanToReadableFormat = val => (val ? 'On' : 'Off')
