import React, { forwardRef, HTMLAttributes, ReactNode, RefObject } from 'react'
import { useModal, useOverlay, usePreventScroll } from '@react-aria/overlays'
import { mergeProps, useViewportSize } from '@react-aria/utils'
import { TrayProps } from '@react-types/overlays'
import { DOMRef } from '@react-types/shared'
import { useDOMRef } from '../../utils'
import clsx from 'clsx'
import { Underlay } from './Underlay'
import { Overlay } from './Overlay'
import styles from './styles.module.scss'

export type TrayWrapperProps = HTMLAttributes<HTMLElement> & {
  children: ReactNode
  isOpen?: boolean
  onClose?: () => void
  isFixedHeight?: boolean
  isNonModal?: boolean
  overlayProps: HTMLAttributes<HTMLElement>
}

const Tray = (props: TrayProps, ref: DOMRef<HTMLDivElement>) => {
  const { children, onClose, isFixedHeight, isNonModal, ...otherProps } = props
  const domRef = useDOMRef(ref)
  const { overlayProps, underlayProps } = useOverlay(
    { ...props, isDismissable: true },
    domRef
  )

  return (
    <Overlay {...otherProps}>
      <Underlay {...underlayProps} />
      <TrayWrapper
        isOpen={props.isOpen}
        onClose={onClose}
        ref={domRef}
        overlayProps={overlayProps}
        isFixedHeight={isFixedHeight}
        isNonModal={isNonModal}
        {...props}
      >
        {children}
      </TrayWrapper>
    </Overlay>
  )
}

const TrayWrapper = forwardRef(function (
  props: TrayWrapperProps,
  ref: RefObject<HTMLDivElement>
) {
  const {
    children,
    isOpen,
    isFixedHeight,
    isNonModal,
    overlayProps,
    ...otherProps
  } = props
  usePreventScroll()
  const { modalProps } = useModal({
    isDisabled: isNonModal,
  })

  // We need to measure the window's height in JS rather than using percentages in CSS
  // so that contents (e.g. menu) can inherit the max-height properly. Using percentages
  // does not work properly because there is nothing to base the percentage on.
  // We cannot use vh units because mobile browsers adjust the window height dynamically
  // when the address bar/bottom toolbars show and hide on scroll and vh units are fixed.
  // Also, the visual viewport is smaller than the layout viewport when the virtual keyboard
  // is up, so use the VisualViewport API to ensure the tray is displayed above the keyboard.
  const viewport = useViewportSize()
  const wrapperStyle: any = {
    '--visual-viewport-height': viewport.height + 'px',
  }

  const wrapperClassName = styles.traywrapper

  const className = clsx(
    styles.tray,
    { [styles.isopen]: isOpen },
    { [styles.isfixedheight]: isFixedHeight },
    otherProps.className
  )

  const domProps = mergeProps(otherProps, overlayProps)

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      <div {...domProps} {...modalProps} className={className} ref={ref}>
        {children}
      </div>
    </div>
  )
})

const _Tray = forwardRef(Tray)
export { _Tray as Tray }
