import clsx from 'clsx'
import { AnchorHTMLAttributes } from 'react'
import { useHover } from '@react-aria/interactions'
import { mergeProps } from '@react-aria/utils'
import styles from './styles.module.scss'

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant?: 'brand' | 'md' | 'hi'
}

export const Anchor = ({
  children,
  className,
  variant = 'hi',
  ...rest
}: AnchorProps) => {
  const { hoverProps, isHovered } = useHover({})
  const anchorCN = clsx(
    styles.anchor,
    {
      [styles.hi]: variant === 'hi',
      [styles.hiHover]: variant === 'hi' && isHovered,
      [styles.md]: variant === 'md',
      [styles.mdHover]: variant === 'md' && isHovered,
      [styles.brand]: variant === 'brand',
      [styles.brandHover]: variant === 'brand' && isHovered,
    },
    className
  )

  return (
    <a {...mergeProps(rest, hoverProps)} className={anchorCN}>
      {children}
    </a>
  )
}
