import { ENTITY } from 'src/consts'
import { NFTCollection } from 'src/models'
import { NFTCollections } from 'src/services/NFTCollectionServices'
import TreePack from 'src/components/TreeMap/components/TreePack'
import { TreeMapHelper } from 'src/components/TreeMap/components/TreeMapHelper'

export type MinMaxFilteredTree = {
  marketCap: number
  volume: number
}

export type FilteredTree = {
  name: string
  maxValues: MinMaxFilteredTree
  minValues: MinMaxFilteredTree
  children: NFTCollection[]
}

export const buildNFTTree = (collection: NFTCollections) => {
  return {
    name: ENTITY.NFT,
    children: collection?.data,
    minValues: { ...collection?.min },
    maxValues: { ...collection?.max },
  }
}

export type buildCompleteNFTTreeArgs = {
  width: number
  height: number
  filteredTree: FilteredTree
  dependsOn?: Pick<
    any,
    | 'limit'
    | 'period'
    | 'blockchain'
    | 'dependsOn'
    | 'blockvalue'
    | 'floorprice'
    | 'currency'
  >
}

export const buildCompleteNFTTree = ({
  filteredTree,
  width,
  height,
  dependsOn,
}: buildCompleteNFTTreeArgs) => {
  const currentBaseValue = dependsOn || 'volume'

  const treePack = new TreePack({
    data: filteredTree,
    baseValue: currentBaseValue,
    range: [1e-3, 100, 350],
  })

  TreeMapHelper({
    width,
    height,
    isMobile: false,
    treeRoot: treePack.treeRoot,
  })

  const groups = treePack.groups
  const leaves = treePack.leaves

  return {
    groups,
    leaves,
  }
}
