import { newsSources } from './newsSources'

const NEWS_LINE_SRC = newsSources.newsLine
const NEWS_LIST_SRC = newsSources.newsList
const NEWS_SRC_COIN_CARD = newsSources.page

const NEWS_COUNT = Object.entries(NEWS_LIST_SRC).reduce(
  (prev, next) => prev + next[1].count,
  0
)

export const CONFIG = {
  AUTO_UPDATE_LEAF_ANIMATION_DURATION: 3, // sec
  AUTO_UPDATE_DATA_FETCHING_MINIMUM_TIMEOUT: 8, // sec
  MAX_TIME_INBETWEEN_ANIMATIONS: 9, // sec
  AUTO_UPDATE_LEAF_ALL_ANIMAIONS_MAX: 8, // sec, not used currently
  AUTO_UPDATE_CANVAS_RERENDER_INTERVAL: 150, // sec, not used currently
  AUTO_UPDATE_CLEAR_ANIMATION_TIMEOUTS: 1.5 * 60, // mins, not used currently
  API_ROOT_HTTP: process.env.NEXT_PUBLIC_API_ENDPOINT,
  BASE_TIMESTAMP: 1367179200000,
  IS_MARKET_GREEN: false, // component of grass animation is disabled in Home
  NEWS_LINE_SRC,
  NEWS_LIST_SRC,
  NEWS_SRC_COIN_CARD,
  NEWS_COUNT,
  NEWS_SRC_STRING: Object.keys(NEWS_LIST_SRC).join(','),
  NEWS_SRC_COIN_CARD_STRING: Object.keys(NEWS_SRC_COIN_CARD).join(','),
  NEWS_REQUEST_LIMIT: 12,
  BTC_ROCKET_ENABLED: false,
}
