import { HYDRATE } from 'next-redux-wrapper'
import {
  SET_EXCHANGE_DATA,
  SET_EXCHANGE_DATA_ERROR,
  REFRESH_EXCHANGE_DATA,
} from 'src/redux/actions'

const initialState = {
  data: null,
  error: null,
}

export const exchange = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.exchange,
      }
    case SET_EXCHANGE_DATA:
      return {
        ...state,
        data: action.payload,
        error: null,
      }
    case SET_EXCHANGE_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case REFRESH_EXCHANGE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    default:
      return { ...state }
  }
}

export default exchange
