import ReactMarkdown from 'react-markdown'
import { MarkdownAnchor } from './components/MarkdownAnchor'

export type MarkdownRenderProps = {
  className?: string
  children: string
}

export const MarkdownRender = ({
  className = '',
  children,
}: MarkdownRenderProps) => {
  return (
    <ReactMarkdown className={className} components={{ a: MarkdownAnchor }}>
      {children}
    </ReactMarkdown>
  )
}
