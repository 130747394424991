import { createSelector } from 'reselect'
import { getMapDataArrayRaw, getMapDataFromStore } from './mapdata'
import { buildTreeMap } from 'src/core/buildTreeMap'

const getWatchListFromStore = state => state.watchlist

export const getActiveWatchlist = createSelector(
  getWatchListFromStore,
  data => {
    if (data.watchlistItems && data.watchlistItems.length > 0) {
      return (
        data.watchlistItems.find(item => item.id === data.activeTab) || {
          data: [],
        }
      )
    }
    return { data: [] }
  }
)

export const selectWatchlistLoading = createSelector(
  getWatchListFromStore,
  data => data.isLoading
)

export const getAllWatchListItems = createSelector(
  getWatchListFromStore,
  data => data.watchlistItems
)

export const getLatestActiveTab = createSelector(
  getWatchListFromStore,
  data => data.latestActiveTab
)

export const getActiveWatchlistId = createSelector(
  getWatchListFromStore,
  data => data.activeTab
)

export const getCurrentWatchListItems = createSelector(
  getActiveWatchlist,
  activeWatchList => activeWatchList?.data || []
)

export const getPopularCoinAndTokens = createSelector(
  [getMapDataArrayRaw, getCurrentWatchListItems],
  (mapDataArray, watchListItems) => {
    let nextData = mapDataArray
    if (watchListItems && watchListItems.length > 0) {
      nextData = nextData.filter(
        item => !watchListItems.find(e => e === item.symbol)
      )
    }
    return nextData.slice(0, 4)
  }
)

export const getWatchListMapDataArray = createSelector(
  [getMapDataArrayRaw, getActiveWatchlist],
  (unfilteredMapDataArray, watchListDict) => {
    return unfilteredMapDataArray.reduce((acc, item, index) => {
      if (watchListDict?.data.find(e => e === item.symbol)) {
        acc.push({ ...item, index: index + 1 })
      }
      return acc
    }, [])
  }
)

export const getWatchListMapDataTree = createSelector(
  [getMapDataFromStore, getWatchListMapDataArray],
  (mapData, mapDataArray) => buildTreeMap(mapData, mapDataArray, true)
)
