import sortBy from 'lodash/sortBy'
import assign from 'lodash/assign'

export const prepareNews = (newsSources, newsData) => {
  const mutualArray = []
  const resultNewsData = []

  const newsCount = Object.values(newsSources).reduce(function (acc, s) {
    return (acc += s.count)
  }, 0)

  // TODO: use 2-level reduce maybe?
  Object.entries(newsData.result).forEach(function (item) {
    if (
      newsSources[item[0]] &&
      item[1] &&
      Array.isArray(item[1]) &&
      item[1].length > 0
    ) {
      item[1].forEach(function (newsblock) {
        mutualArray.push({
          src: item[0],
          date: newsblock.date.replace(/\.000000/, '').replace(/ \+0000/, ''),
          link: newsblock.link,
          title: newsblock.title,
          author: newsblock.author,
        })
      })
    }
  })

  const sorted = sortBy(mutualArray, function (n) {
    let result = 0
    try {
      result = Date.parse(n.date)
    } catch (err) {
      console.error(err.message) // eslint-disable-line
    }
    return -result
  })

  sorted.forEach(function (item) {
    if (
      resultNewsData.length < newsCount &&
      newsSources[item.src] &&
      newsSources[item.src].count !== 0
    ) {
      resultNewsData.push(
        assign({}, item, { name: newsSources[item.src].fullName })
      )
    }
  })

  return resultNewsData
}
