import React, { useContext, useRef, RefObject, forwardRef } from 'react'
import { filterDOMProps } from '@react-aria/utils'
import { ModalProvider, useModalProvider } from '@react-aria/overlays'
import { ProviderContext, ProviderProps } from '@react-types/provider'
import clsx from 'clsx'
import styles from './styles.module.scss'

const Context = React.createContext<ProviderContext | null>(null)
Context.displayName = 'ProviderContext'

export const Provider = forwardRef(
  (props: ProviderProps, ref: RefObject<HTMLDivElement>) => {
    const prevContext = useProvider()

    const { children, ...otherProps } = props

    const filteredProps = {}

    const context = Object.assign({}, prevContext, filteredProps)

    let contents = children
    const domProps = filterDOMProps(otherProps)

    if (
      !prevContext ||
      props.locale ||
      Object.keys(domProps).length > 0 ||
      otherProps.UNSAFE_className
    ) {
      contents = (
        <ProviderWrapper
          {...props}
          classNames={clsx({ [styles.isolate]: !prevContext })}
          ref={ref}
        >
          {contents}
        </ProviderWrapper>
      )
    }

    return (
      <Context.Provider value={context}>
        <ModalProvider>{contents}</ModalProvider>
      </Context.Provider>
    )
  }
)

export type ProviderWrapperProps = ProviderProps & {
  classNames?: string
}

const ProviderWrapper = React.forwardRef(
  (props: ProviderWrapperProps, ref: RefObject<HTMLDivElement>) => {
    const { children, classNames, ...otherProps } = props

    const { modalProviderProps } = useModalProvider()

    const domRef = useRef(ref?.current)

    const style = styles.provider

    return (
      <div
        {...filterDOMProps(otherProps)}
        {...modalProviderProps}
        className={clsx(style, classNames)}
        ref={domRef}
      >
        {children}
      </div>
    )
  }
)

export const useProvider = (): ProviderContext => {
  return useContext(Context)
}
