import { HYDRATE } from 'next-redux-wrapper'
import { SET_SHARED_CURRENCY, RESET_SHARED_FILTERS } from 'src/redux/actions'
import { getLocalStorageItem } from 'src/utils/localStorage'

export const sharedFiltersDefaults = {
  currency: 'USD',
}

export const sharedFilters = (state = sharedFiltersDefaults, action) => {
  switch (action.type) {
    case HYDRATE:
      const nextState = {
        ...state,
        ...action.payload.filters.shared,
      }
      const storedCurrency = getLocalStorageItem('currency')
      if (storedCurrency) {
        nextState.currency = storedCurrency
      }
      return nextState
    case SET_SHARED_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      }
    case RESET_SHARED_FILTERS:
      return {
        ...sharedFiltersDefaults,
      }
    default:
      return { ...state }
  }
}

export default sharedFilters
