import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

export type TagProps = {
  children: ReactNode
  className?: string
  size?: 'small' | 'regular'
}

export const Tag = ({ children, className, size = 'regular' }: TagProps) => {
  const tagCN = clsx(styles.tag, className, {
    [styles.small]: size === 'small',
    [styles.regular]: size === 'regular',
  })

  return <span className={tagCN}>{children}</span>
}
