export const SET_COINS_STATE = 'SET_COINS_STATE'
export const SET_COINS_GROUP = 'SET_COINS_GROUP'
export const SET_COINS_RANGE = 'SET_COINS_RANGE'
export const SET_COINS_DISPLAY = 'SET_COINS_DISPLAY'
export const SET_COINS_TOP = 'SET_COINS_TOP'
export const SET_COINS_DEPENDS_ON = 'SET_COINS_DEPENDS_ON'
export const ADD_COINS_EXCEPTION = 'ADD_COINS_EXCEPTION'
export const ADD_COINS_EXCEPTION_LIST = 'ADD_COINS_EXCEPTION_LIST'
export const DELETE_COINS_EXCEPTION = 'DELETE_COINS_EXCEPTION'
export const RESET_COINS_ALL_EXCEPTION = 'RESET_COINS_ALL_EXCEPTION'
export const SET_COINS_PERIODS = 'SET_COINS_PERIODS'
export const SET_COINS_RANKING = 'SET_COINS_RANKING'
export const SET_COINS_TAG = 'SET_COINS_TAG'

export const setCoinsInitialStateAction = payload => ({
  type: SET_COINS_STATE,
  payload,
})

export const setCoinsGroupAction = payload => ({
  type: SET_COINS_GROUP,
  payload,
})

export const setCoinsRangeAction = payload => ({
  type: SET_COINS_RANGE,
  payload,
})

export const setCoinsDisplayAction = payload => ({
  type: SET_COINS_DISPLAY,
  payload,
})

export const setCoinsTopAction = payload => ({
  type: SET_COINS_TOP,
  payload,
})

export const setCoinsDependsOnAction = payload => ({
  type: SET_COINS_DEPENDS_ON,
  payload,
})

export const setCoinsPeriodAction = payload => ({
  type: SET_COINS_PERIODS,
  payload,
})

export const addExceptionAction = payload => ({
  type: ADD_COINS_EXCEPTION,
  payload,
})

export const addExceptionListAction = payload => ({
  type: ADD_COINS_EXCEPTION_LIST,
  payload,
})

export const deleteExceptionAction = payload => ({
  type: DELETE_COINS_EXCEPTION,
  payload,
})

export const resetCoinsExceptionsAction = payload => ({
  type: RESET_COINS_ALL_EXCEPTION,
  payload,
})

export const setCoinsRankingAction = payload => ({
  type: SET_COINS_RANKING,
  payload,
})

export const setCoinsTagAction = payload => ({
  type: SET_COINS_TAG,
  payload,
})
