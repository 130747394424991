import {
  deleteUrlSearchParameter,
  updateUrlWithNewSearchParameter,
} from 'src/utils/router'
import { getSubtractDays } from 'src/utils/time/moment.helper'
import {
  getExchangesExceptions,
  getExchangesMapDataMaxValues,
} from 'src/redux/selectors'
import {
  setExchangesPeriodAction,
  setExchangesTopAction,
  addExchangesExceptionListAction,
  deleteExchangesExceptionAction,
  addExchangesExceptionAction,
  resetExchangesExceptionsAction,
  setExchangesGroupAction,
  setExchangesRangeAction,
  setExchangesMarketsRangeAction,
  setExchangesInitialStateAction,
} from 'src/redux/actions'
import cloneDeep from 'lodash/cloneDeep'
import { exchangesFiltersDefaults } from 'src/redux/reducers'
import { ENTITY } from 'src/consts'
import { getDefaultPeriod } from 'src/consts'
import { getRemoteMapData } from 'src/redux/thunks'
import { getFieldOfDataObject } from 'src/utils/currency'

export const setExchangesTop = data => dispatch => {
  dispatch(setExchangesTopAction(data))
  updateUrlWithNewSearchParameter('slice', data)
}

export const changeExchangesPeriod = updatedPeriod => dispatch => {
  const newPeriod = { ...updatedPeriod }

  if (newPeriod.start >= newPeriod.end) {
    newPeriod.start = +getSubtractDays(newPeriod.end, 1)
  }

  dispatch(setExchangesPeriodAction(newPeriod))
  updateUrlWithNewSearchParameter(
    'period',
    newPeriod.active === 'custom'
      ? `[${newPeriod.start},${newPeriod.end}]`
      : newPeriod.active
  )
}

export const addExchangesException = data => (dispatch, getState) => {
  const currentExceptions = getExchangesExceptions(getState())

  if (Array.isArray(data)) {
    dispatch(addExchangesExceptionListAction(data))
    updateUrlWithNewSearchParameter(
      'exceptions',
      `[${[...currentExceptions, [...data]].join()}]`
    )
  } else {
    dispatch(addExchangesExceptionAction(data))
    updateUrlWithNewSearchParameter(
      'exceptions',
      `[${[...currentExceptions, data].join()}]`
    )
  }
}

export const deleteExchangesException = data => (dispatch, getState) => {
  const currentExceptions = getExchangesExceptions(getState())

  dispatch(deleteExchangesExceptionAction(data))
  updateUrlWithNewSearchParameter(
    'exceptions',
    `[${currentExceptions.filter(i => i !== data).join()}]`
  )
}

export const resetExchangesExceptions = () => dispatch => {
  dispatch(resetExchangesExceptionsAction())
  updateUrlWithNewSearchParameter('exceptions', '[]')
}

export const setExchangesGroup = data => dispatch => {
  dispatch(setExchangesGroupAction(data))
  updateUrlWithNewSearchParameter('group', data)
}

export const setExchangesRange =
  (range, url = true) =>
  dispatch => {
    dispatch(setExchangesRangeAction(range))
    updateUrlWithNewSearchParameter('range', url ? `[${range}]` : undefined)
  }

export const resetExchangesRange = range => dispatch => {
  dispatch(setExchangesRangeAction(range))
  deleteUrlSearchParameter('range')
}

export const setExchangesMarketsRange =
  (markets, url = true) =>
  dispatch => {
    dispatch(setExchangesMarketsRangeAction(markets))
    updateUrlWithNewSearchParameter('markets', url ? `[${markets}]` : undefined)
  }

export const resetExchangesFiltersAll = () => (dispatch, getState) => {
  updateUrlWithNewSearchParameter('', 'reset')

  const newState = cloneDeep(exchangesFiltersDefaults)
  const maxValues = getExchangesMapDataMaxValues(getState())
  const maxValueMarkets = getFieldOfDataObject(maxValues, 'pairs')
  newState.period = getDefaultPeriod()
  newState.markets = [0, maxValueMarkets]
  newState.range = [0, maxValues.volumeReported]

  dispatch(setExchangesInitialStateAction(newState))

  dispatch(
    getRemoteMapData({
      entity: ENTITY.EXCHANGE,
      forceBaseAndRangeReset: true,
    })
  )
}
