import React, { useRef } from 'react'
import { useButton } from '@react-aria/button'
import { AriaButtonProps } from '@react-types/button'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type ButtonProps = AriaButtonProps & {
  className?: string
  size?: 'sm' | 'md'
  variant: 'primary' | 'secondary' | 'tertiary'
}

export const Button = (props: ButtonProps) => {
  const {
    elementType: Component = 'button',
    children,
    className,
    size = 'md',
    variant = 'primary',
  } = props
  const ref = useRef()
  const { buttonProps } = useButton(props, ref)

  return (
    <Component
      className={clsx(styles.button, styles[size], styles[variant], className)}
      {...buttonProps}
      ref={ref}
    >
      {children}
    </Component>
  )
}
