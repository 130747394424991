import { useCallback, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import styles from './styles.module.scss'
import { fitTextFontSizeNft } from 'src/utils'
import { switchCurrency } from 'src/utils/currency'

BigNumber.config({ DECIMAL_PLACES: 16, EXPONENTIAL_AT: 1e9 })

type Params = {
  floorprice?: string
  blockvalue?: string
  currency?: string
}

export type Props = {
  leaf: any
  entry: any
  group: any
  index: string
  params: Params
}

export const NftLeaf = ({ leaf, entry, group, index, params }: Props) => {
  const { name, imgUrl, floorPriceBlockchain, change, blockchain, address } =
    leaf.data
  const { floorprice, blockvalue, currency = 'USD' } = params
  const [image, setImage] = useState(imgUrl || '/assets/nft-placeholder.svg') // if we don't have image in response
  const href = useMemo(() => `/nft/${address}`, [address])
  const width = useMemo(() => entry.x1 - entry.x0, [entry])
  const height = useMemo(() => entry.y1 - entry.y0, [entry])

  useEffect(() => {
    imgUrl && setImage(imgUrl)
  }, [imgUrl])

  const isLeafVisible = useMemo(
    () => width > 10 && height > 10,
    [width, height]
  )

  const isImageVisible = useMemo(
    () => width > 16 && height > 36 && (height > width / 2 || height > 40),
    [width, height]
  )

  const isFloorVisible = useMemo(
    () => width > 100 && height > 100,
    [width, height]
  )

  const cssTransform = useMemo(
    () => `translate3d(${entry.x0 - group.x0}px, ${entry.y0 - group.y0}px, 0)`,
    [entry.x0, entry.y0, group.x0, group.y0]
  )

  const wrapperProps = useMemo(
    () => ({
      className: styles.leaf,
      'data-index': index,
      style: {
        transform: cssTransform,
        width,
        height,
        willChange: 'transform',
      },
    }),
    [cssTransform, height, width, index]
  )

  const previewSize = useMemo(
    function () {
      const size = width > height ? width : height
      switch (true) {
        case size < 33:
          return 32
          break
        case size < 129:
          return 128
          break
        case size < 257:
          return 256
          break
        case size < 513:
          return 512
          break
        case size < 1025:
          return 1024
          break
        default:
          return 0
      }
    },
    [width, height]
  )

  const getLabelFontSize = useCallback(
    maxFontSize =>
      fitTextFontSizeNft({
        width,
        height,
        maxFontSize,
      }),
    [width, height]
  )

  const handleClick = e => {
    e.stopPropagation()
    // track additional events during redirect
  }

  const setPropperImage = e => {
    const leafBlock = e.target.classList.contains(styles.onLoad)
      ? e.target
      : e.target.closest('a.' + styles.onLoad)
    if (!!leafBlock) leafBlock.classList.remove(styles.onLoad)
  }

  const setPlaceholderImageBack = e => {
    const leafBlock = e.target.classList.contains(styles.mockLoad)
      ? e.target
      : e.target.closest('a.' + styles.mockLoad)
    if (!!leafBlock) leafBlock.classList.add(styles.onLoad)
  }

  return isLeafVisible ? (
    <Link href={href} prefetch={false}>
      <a
        onClick={handleClick}
        className={[
          styles.onLoad,
          styles.mockLoad,
          parseInt(index) < 50 ? styles.forceLoad : null,
        ].join(' ')}
        onMouseEnter={setPropperImage}
        onMouseLeave={setPlaceholderImageBack}
      >
        <div {...wrapperProps}>
          {isImageVisible && (
            <div
              className={styles.imageContainer}
              style={{
                backgroundImage:
                  'url(/site-api/thumbnails?url=' +
                  encodeURIComponent(image) +
                  (previewSize != 0 ? '&size=' + previewSize : '') +
                  ')',
              }}
            >
              <div
                className={styles.imageCover}
                style={{
                  backgroundImage: 'url(' + image + ')',
                }}
              ></div>
            </div>
          )}
          {floorprice !== 'false' && isFloorVisible && (
            <div className={styles.floorPice}>
              <Image
                src={`/assets/symbols/${blockchain.toLowerCase()}.svg`}
                alt={blockchain}
                layout='fixed'
                width={16}
                height={16}
              />
              <div className={styles.floorPiceValue}>
                {BigNumber(floorPriceBlockchain).toPrecision()}
              </div>
            </div>
          )}
          <div className={styles.infoBlock}>
            <div
              className={styles.name}
              style={{
                fontSize: getLabelFontSize(28),
              }}
            >
              {name}
            </div>
            <div
              className={styles.secondaryBlock}
              style={{
                fontSize: getLabelFontSize(24),
              }}
            >
              <>
                {blockvalue === undefined ||
                blockvalue == 'volume' ||
                blockvalue == 'floorPrice'
                  ? switchCurrency(currency, {
                      price: leaf.data[blockvalue || 'volume'],
                    })
                  : numeral(leaf.data[blockvalue]).format(
                      '0,0[.][00000000]',
                      Math.trunc
                    )}
              </>
              {blockvalue === undefined || blockvalue == 'volume' ? (
                <span className={styles.percentDash}>
                  {change > 0 && '+'}
                  {numeral(change).format('0,0[.][00]')}%
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </a>
    </Link>
  ) : (
    <></>
  )
}
