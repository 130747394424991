import { ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export type ChipProps = {
  children?: ReactNode
  icon?: ReactNode
  className?: string
}

export const Chip = ({ children, icon, className }: ChipProps) => {
  return (
    <div className={clsx(styles.chip, className)}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </div>
  )
}
