import { intl } from 'src/i18n'

export const limitOptions = [
  {
    label: intl.t('nft.filters.limit.top30'),
    value: '30',
  },
  {
    label: intl.t('nft.filters.limit.top50'),
    value: '50',
  },
  {
    label: intl.t('nft.filters.limit.top100'),
    value: '100',
  },
  {
    label: intl.t('nft.filters.limit.top200'),
    value: '200',
  },
  {
    label: intl.t('nft.filters.limit.top500'),
    value: '500',
  },
  {
    label: intl.t('nft.filters.limit.all'),
    value: 'all',
  },
]

export const periodOptions = [
  {
    label: intl.t('nft.filters.period.day'),
    value: '24h',
  },
  {
    label: intl.t('nft.filters.period.week'),
    value: '7d',
  },
  {
    label: intl.t('nft.filters.period.month'),
    value: '30d',
  },
]

export const blockchainOptions = [
  {
    label: intl.t('nft.filters.blockchain.all'),
    value: 'all',
  },
  {
    label: intl.t('nft.filters.blockchain.ethereum'),
    value: 'ETHEREUM',
  },
  // {
  //   label: intl.t('nft.filters.blockchain.tezos'),
  //   value: 'TEZOS',
  // },
  // {
  //   label: intl.t('nft.filters.blockchain.solana'),
  //   value: 'SOLANA',
  // },
  // {
  //   label: intl.t('nft.filters.blockchain.polygon'),
  //   value: 'POLYGON',
  // },
  {
    label: intl.t('nft.filters.blockchain.immutablex'),
    value: 'IMMUTABLEX',
  },
]

export const blocksizeOptions = [
  {
    label: intl.t('nft.filters.blocksize.volume'),
    value: 'volume',
  },
  // {
  //   label: intl.t('nft.filters.blocksize.marketCap'),
  //   value: 'marketCap',
  // },
]

export const blockvalueOptions = [
  {
    label: intl.t('nft.filters.blockvalue.volume'),
    value: 'volume',
  },
  {
    label: intl.t('nft.filters.blockvalue.floorPrice'),
    value: 'floorPrice',
  },
  {
    label: intl.t('nft.filters.blockvalue.numberOfOwners'),
    value: 'numberOfOwners',
  },
  {
    label: intl.t('nft.filters.blockvalue.numberOfItems'),
    value: 'numberOfItems',
  },
]
