import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidUpdate from 'src/hooks/useDidUpdate'
import { getRemoteMapData } from 'src/redux/thunks'
import {
  getSharedFiltersCurrency,
  getPeriodByEntity,
} from 'src/redux/selectors'

const useFetchMapData = ({ entity }) => {
  const intervalRef = useRef()
  const dispatch = useDispatch()
  const currency = useSelector(getSharedFiltersCurrency)
  const period = useSelector(getPeriodByEntity)

  useEffect(() => {
    dispatch(getRemoteMapData({ entity }))
    intervalRef.current = setInterval(
      () => dispatch(getRemoteMapData({ entity })),
      10000
    )

    return () => clearInterval(intervalRef.current)
  }, [dispatch, entity, intervalRef])

  useDidUpdate(() => {
    clearInterval(intervalRef.current)
    dispatch(getRemoteMapData({ entity }))
    intervalRef.current = setInterval(
      () => dispatch(getRemoteMapData({ entity })),
      10000
    )

    return () => clearInterval(intervalRef.current)
  }, [currency, period])
}

export default useFetchMapData
