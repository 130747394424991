import { useRouter } from 'next/router'
import { Item, Section } from '@react-stately/collections'
import { filteredNFTParams } from 'src/utils'
import { Select } from 'src/360ui/Select'
import { updateUrlWithNewSearchParameter } from 'src/360ui/utils/router'
import { CURRENCY_SELECT_OPTIONS } from 'src/consts'
import { intl } from 'src/i18n'
import { useBreackpoints } from 'src/hooks/useBreackpoints'
import { DesktopView } from './DesktopView'

export const CurrencySelect = () => {
  const { query } = useRouter()
  const filters = filteredNFTParams(query)
  const match = useBreackpoints('lg')

  const renderForSmallScreen = () => (
    <Select
      label={intl.t('nft.filters.currency.label')}
      items={CURRENCY_SELECT_OPTIONS}
      selectedKey={
        filters.currency || CURRENCY_SELECT_OPTIONS[0].children[0].value
      }
      onSelectionChange={selected =>
        updateUrlWithNewSearchParameter('currency', selected as string)
      }
    >
      {item => (
        <Section key={item.name} items={item.children} title={item.name}>
          {item => <Item key={item.value}>{item.value}</Item>}
        </Section>
      )}
    </Select>
  )

  return match.lg ? (
    renderForSmallScreen()
  ) : (
    <DesktopView
      selectedKey={
        filters.currency || CURRENCY_SELECT_OPTIONS[0].children[0].value
      }
    />
  )
}
