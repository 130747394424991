import TreePack, {
  SCALING_CONTRAST,
  SCALING_SMOOTH,
} from 'src/components/TreeMap/components/TreePack'
import { TreeMapHelper } from 'src/components/TreeMap/components/TreeMapHelper'
import { getEntityMapRange, ENTITY } from 'src/consts'

export const getCompleteMapDataTree = ({
  baseValue,
  filteredTree,
  width = 1920,
  height = 1080,
  isMobile = false,
  onWatchlist = false,
  entity,
  topLoosersAndGainers = undefined,
}) => {
  if (!(filteredTree && filteredTree.children)) return null

  const range = getEntityMapRange(entity)

  let currentBaseValue = baseValue
  if (entity === ENTITY.EXCHANGE) {
    currentBaseValue =
      baseValue === 'volumeReported' ? 'scaledVolumeReported' : 'scaledVolume'
  }

  const diffLimit = onWatchlist ? 50 : undefined
  const scaling =
    !!topLoosersAndGainers && topLoosersAndGainers !== 'all'
      ? SCALING_CONTRAST
      : SCALING_SMOOTH

  const treePack = new TreePack({
    data: filteredTree,
    baseValue: currentBaseValue,
    range,
    diffLimit,
    scaling,
  })

  TreeMapHelper({
    width,
    height,
    isMobile,
    treeRoot: treePack.treeRoot,
  })

  const groups = treePack.groups
  const leaves = treePack.leaves

  return {
    groups,
    leaves,
  }
}
