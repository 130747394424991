import { intl } from 'src/i18n'
/*
*
* SGD (Singapore Dollar)

INR (Indian Rupee)

KZT (Kazakhstani Tenge)

HKD (Hong Kong Dollar)
* */
export const CURRENCY_SELECT_OPTIONS = [
  {
    name: 'Fiat',
    children: [
      {
        value: 'USD',
        label: intl.t('nft.filters.currency.usd'),
        icon: '/assets/currency/USD-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'EUR',
        label: intl.t('nft.filters.currency.eur'),
        icon: '/assets/currency/EUR-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'GBP',
        label: intl.t('nft.filters.currency.gbp'),
        icon: '/assets/currency/GBP-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'CNY',
        label: intl.t('nft.filters.currency.cny'),
        icon: '/assets/currency/CNY-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'JPY',
        label: intl.t('nft.filters.currency.jpy'),
        icon: '/assets/currency/JPY-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'CAD',
        label: intl.t('nft.filters.currency.cad'),
        icon: '/assets/currency/CAD-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'BRL',
        label: intl.t('nft.filters.currency.blr'),
        icon: '/assets/currency/BRL-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'TRY',
        label: intl.t('nft.filters.currency.try'),
        icon: '/assets/currency/TRY-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'RUB',
        label: intl.t('nft.filters.currency.rub'),
        icon: '/assets/currency/RUB-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'IRR',
        label: intl.t('nft.filters.currency.irr'),
        icon: '/assets/currency/IRR-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'UAH',
        label: intl.t('nft.filters.currency.uah'),
        icon: '/assets/currency/UAH-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'SGD',
        label: intl.t('nft.filters.currency.sgd'),
        icon: '/assets/currency/SGD-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'INR',
        label: intl.t('nft.filters.currency.inr'),
        icon: '/assets/currency/INR-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'KZT',
        label: intl.t('nft.filters.currency.kzt'),
        icon: '/assets/currency/KZT-filter-basecurrency.svg',
        fiat: true,
      },
      {
        value: 'HKD',
        label: intl.t('nft.filters.currency.hkd'),
        icon: '/assets/currency/HKD-filter-basecurrency.svg',
        fiat: true,
      },
    ],
  },
  {
    name: 'Cryptocurrencies',
    children: [
      {
        value: 'USDT',
        label: intl.t('nft.filters.currency.usdt'),
        icon: '/assets/currency/USDT-filter-basecurrency.svg',
        fiat: false,
      },
      {
        value: 'BTC',
        label: intl.t('nft.filters.currency.btc'),
        icon: '/assets/currency/BTC-filter-basecurrency.svg',
        fiat: false,
      },
      {
        value: 'ETH',
        label: intl.t('nft.filters.currency.eth'),
        icon: '/assets/currency/ETH-filter-basecurrency.svg',
        fiat: false,
      },
      {
        value: 'EOS',
        label: intl.t('nft.filters.currency.eos'),
        icon: '/assets/currency/EOS-filter-basecurrency.svg',
        fiat: false,
      },
    ],
  },
]
