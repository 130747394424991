import { SET_AVATAR_URL } from '../actions/avatar'

const initialState = {
  avatarUrl: null,
}

export const avatar = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVATAR_URL:
      return {
        ...state,
        avatarUrl: action.payload,
      }
    default:
      return state
  }
}
