import { HYDRATE } from 'next-redux-wrapper'
import { SET_ALL_NEWS, GET_NEWS_ERROR } from 'src/redux/actions'

const initialState = {
  lineData: [],
  listData: [],
  error: null,
}

export const news = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.news,
      }
    case SET_ALL_NEWS:
      return {
        ...state,
        ...action.data,
      }
    case GET_NEWS_ERROR:
      return {
        ...state,
        error: action.data,
      }
    default:
      return { ...state }
  }
}

export default news
