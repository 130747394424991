import { createSelector } from 'reselect'
import { getCurrentEntity } from '../ui'
import { getCoinsDependsOn, getCoinsPeriod } from './coins'
import { getExchangesDependsOn, getExchangesPeriod } from './exchanges'
import { chooseByEntity } from 'src/consts'

export const getDependsOnByEntity = createSelector(
  [getCurrentEntity, getCoinsDependsOn, getExchangesDependsOn],
  chooseByEntity
)

export const getPeriodByEntity = createSelector(
  [getCurrentEntity, getCoinsPeriod, getExchangesPeriod],
  chooseByEntity
)
