import { OverlayProps } from '@react-types/overlays'
import { Provider } from '../../provider'
import React, { Ref, useState } from 'react'
import ReactDOM from 'react-dom'

function Overlay(props: OverlayProps, ref: Ref<HTMLDivElement>) {
  const { children, isOpen, container } = props
  const [exited] = useState(!isOpen)

  const mountOverlay = isOpen || !exited
  if (!mountOverlay) {
    return null
  }

  const contents = (
    <Provider
      ref={ref}
      UNSAFE_style={{ background: 'transparent', isolation: 'isolate' }}
    >
      {children}
    </Provider>
  )

  return ReactDOM.createPortal(contents, container || document.body)
}

const _Overlay = React.forwardRef(Overlay)
export { _Overlay as Overlay }
