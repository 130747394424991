export const transformObjectKeys = (
  params,
  config: { [key: string]: string }
) => {
  const newObj = {}

  for (const i in params) {
    if (i in config) {
      newObj[config[i]] = params[i]
    } else {
      newObj[i] = params[i]
    }
  }

  return newObj
}
