import React, { useRef } from 'react'
import { useSwitch } from '@react-aria/switch'
import { useToggleState } from '@react-stately/toggle'
import { AriaSwitchProps } from '@react-types/switch'
import styles from './styles.module.scss'
import clsx from 'clsx'

export type SwitchProps = AriaSwitchProps & {
  className?: string
}

export const Switch = (props: SwitchProps) => {
  const { children, className } = props
  const state = useToggleState(props)
  const ref = useRef()
  const { inputProps } = useSwitch(props, state, ref)

  return (
    <label className={clsx(styles.label, className)}>
      {children}
      <input
        className={clsx(styles.toggle, { [styles.checked]: state.isSelected })}
        {...inputProps}
        ref={ref}
      />
    </label>
  )
}
