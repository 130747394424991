import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import { getMapDataSelector } from './mapdata'

export const getExchangeDataSelector = ({ exchange }) => exchange.data

export const getCompleteExchangeEntityData = createSelector(
  [getExchangeDataSelector, getMapDataSelector],
  (data, mapData) => {
    const dataArray = sortBy(mapData.exchanges.data || [], [
      d => d.type !== 'Verified',
      d => -d.volumeReported,
    ]).map((item, index) => ({
      ...item,
      index: index + 1,
    }))
    const currentEntityId = data?.slug

    if (!Array.isArray(dataArray) || !currentEntityId) {
      return
    }

    const mapExchangeData =
      dataArray.find(d => d.slug === currentEntityId) || {}

    return {
      ...data,
      ...mapExchangeData,
    }
  }
)
