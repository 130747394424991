export const SET_ALL_NEWS = 'SET_ALL_NEWS'
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR'

export const setAllNews = data => ({
  type: SET_ALL_NEWS,
  data,
})

export const getNewsError = data => ({
  type: GET_NEWS_ERROR,
  data,
})
