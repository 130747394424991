import { intl } from 'src/i18n'

export const coinsFiltersOptions = {
  period: [
    {
      value: '1h',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.period.1h'),
    },
    {
      value: '24h',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.period.24h'),
    },
    {
      value: '7d',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.period.7d'),
    },
    {
      value: '30d',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.period.30d'),
    },
    {
      value: 'custom',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.period.custom'
      ),
    },
  ],
  display: [
    {
      value: 'all',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.display.all'),
    },
    {
      value: 'coins',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.display.coins'
      ),
    },
    {
      value: 'tokens',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.display.tokens'
      ),
    },
  ],
  top: [
    {
      value: 'all',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.top.all'),
    },
    {
      value: 'losers50',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.top.losers50'),
    },
    {
      value: 'losers100',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.top.losers100'
      ),
    },
    {
      value: 'losers200',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.top.losers200'
      ),
    },
    {
      value: 'gainers50',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.top.gainers50'
      ),
    },
    {
      value: 'gainers100',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.top.gainers100'
      ),
    },
    {
      value: 'gainers200',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.top.gainers200'
      ),
    },
  ],
  dependsOn: [
    {
      value: 'marketCap',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.dependsOn.marketCap'
      ),
    },
    {
      value: 'volume',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.dependsOn.volume'
      ),
    },
  ],
  ranking: [
    {
      value: 'all',
      label: intl.t('constants.filterOptions.coinsFiltersOptions.ranking.all'),
    },
    {
      value: 'top100',
      label: intl.t(
        'constants.filterOptions.coinsFiltersOptions.ranking.top100'
      ),
    },
  ],
  tag: [
    {
      value: 'Layer 1',
      label: 'Layer 1',
    },
    {
      value: 'Bitcoin Ecosystem',
      label: 'Bitcoin Ecosystem',
    },
    {
      value: 'Optimism Ecosystem',
      label: 'Optimism Ecosystem',
    },
    {
      value: 'USD Stablecoin',
      label: 'USD Stablecoin',
    },
    {
      value: 'Ethereum Ecosystem',
      label: 'Ethereum Ecosystem',
    },
    {
      value: 'Arbitrum Ecosystem',
      label: 'Arbitrum Ecosystem',
    },
    {
      value: 'Solana Ecosystem',
      label: 'Solana Ecosystem',
    },
    {
      value: 'Memes',
      label: 'Memes',
    },
    {
      value: 'Gaming',
      label: 'Gaming',
    },
    {
      value: 'Stablecoin',
      label: 'Stablecoin',
    },
    {
      value: 'Smart Contracts',
      label: 'Smart Contracts',
    },
    {
      value: 'BNB Chain Ecosystem',
      label: 'BNB Chain Ecosystem',
    },
    {
      value: 'DeFi',
      label: 'DeFi',
    },
  ],
}

export const currenciesOptions = {
  currency: [
    { value: 'USD', label: 'United States dollar', fiat: true },
    { value: 'USDT', label: 'Tether', fiat: false },
    { value: 'BTC', label: 'Bitcoin', fiat: false },
    { value: 'ETH', label: 'Ethereum', fiat: false },
    { value: 'EOS', label: 'EOS', fiat: false },
    { value: 'EUR', label: 'Euro', fiat: true },
    { value: 'GBP', label: 'British Pound Sterling', fiat: true },
    { value: 'CNY', label: 'Chinese Yuan', fiat: true },
    { value: 'JPY', label: 'Japanese Yen', fiat: true },
    { value: 'CAD', label: 'Canadian Dollar', fiat: true },
    { value: 'BRL', label: 'Brazilian Real', fiat: true },
    { value: 'TRY', label: 'Turkish Lira', fiat: true },
    { value: 'RUB', label: 'Russian Ruble', fiat: true },
    { value: 'IRR', label: 'Iranian Rial', fiat: true },
    { value: 'UAH', label: 'Ukrainian Hryvnia', fiat: true },
    { value: 'INR', label: 'Indian Rupee', fiat: true },
    { value: 'SGD', label: 'Singapore Dollar', fiat: true },
    { value: 'HKD', label: 'Hong Kong Dollar', fiat: true },
    { value: 'KZT', label: 'Kazakhstani Tenge', fiat: true },
  ],
}

export const sharedFiltersOptions = {
  currency: [
    { value: 'USD', label: 'USD', fiat: true },
    { value: 'USDT', label: 'USDT', fiat: false },
    { value: 'BTC', label: 'BTC', fiat: false },
    { value: 'ETH', label: 'ETH', fiat: false },
    { value: 'EOS', label: 'EOS', fiat: false },
    { value: 'EUR', label: 'EUR', fiat: true },
    { value: 'GBP', label: 'GBP', fiat: true },
    { value: 'CNY', label: 'CNY', fiat: true },
    { value: 'JPY', label: 'JPY', fiat: true },
    { value: 'CAD', label: 'CAD', fiat: true },
    { value: 'BRL', label: 'BRL', fiat: true },
    { value: 'TRY', label: 'TRY', fiat: true },
    { value: 'RUB', label: 'RUB', fiat: true },
    { value: 'IRR', label: 'IRR', fiat: true },
    { value: 'UAH', label: 'UAH', fiat: true },
    { value: 'INR', label: 'INR', fiat: true },
    { value: 'SGD', label: 'SGD', fiat: true },
    { value: 'HKD', label: 'HKD', fiat: true },
    { value: 'KZT', label: 'KZT', fiat: true },
  ],
}

export const exchangesFiltersOptions = {
  period: [
    {
      value: '24h',
      label: intl.t(
        'constants.filterOptions.exchangesFiltersOptions.period.24h'
      ),
    },
    {
      value: '7d',
      label: intl.t(
        'constants.filterOptions.exchangesFiltersOptions.period.7d'
      ),
    },
    {
      value: '30d',
      label: intl.t(
        'constants.filterOptions.exchangesFiltersOptions.period.30d'
      ),
    },
  ],
  top: [
    {
      value: 'all',
      label: intl.t('constants.filterOptions.exchangesFiltersOptions.top.all'),
    },
    {
      value: 'losers50',
      label: intl.t(
        'constants.filterOptions.exchangesFiltersOptions.top.losers50'
      ),
    },
    {
      value: 'gainers50',
      label: intl.t(
        'constants.filterOptions.exchangesFiltersOptions.top.gainers50'
      ),
    },
  ],
}
