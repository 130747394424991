export const GET_MAPDATA = 'GET_MAPDATA'
export const GET_MAPDATA_ERROR = 'GET_MAPDATA_ERROR'

export const remoteGetMapDataSuccess = payload => ({
  type: GET_MAPDATA,
  payload,
})

export const remoteGetMapDataError = payload => ({
  type: GET_MAPDATA_ERROR,
  payload,
})
