import { useEffect } from 'react'
import toast, { Toaster, useToasterStore, ToastBar } from 'react-hot-toast'
import styles from './styles.module.scss'

const TOAST_LIMIT = 1

export const Toast = () => {
  const { toasts } = useToasterStore()

  // Enforce Limit
  useEffect(() => {
    toasts
      .filter(t => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach(t => toast.remove(t.id)) // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts])

  return (
    <Toaster
      position='bottom-center'
      reverseOrder={false}
      toastOptions={{
        // Define default options
        className: styles.container,
        duration: 3000,
        style: {
          background: '#333236',
          color: '#fff',
        },
        // Default options for specific types
        custom: {
          className: styles.container,
          duration: 3000,
          style: {
            background: '#333236',
            color: '#fff',
            padding: '1em',
            fontWeight: 400,
            lineHeight: '1.2em',
          },
        },
      }}
    >
      {t => (
        <ToastBar toast={t} position='bottom-center'>
          {({ icon, message }) => (
            <>
              {icon && <span className={styles.icon}>{icon}</span>}
              {message}
              {t.type !== 'loading' && (
                <button
                  className={styles.button}
                  onClick={() => toast.dismiss(t.id)}
                >
                  Ok
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default Toast
