import { useRef } from 'react'
import Image from 'next/image'
import { useOption, useListBoxSection } from '@react-aria/listbox'
import clsx from 'clsx'
import styles from './styles.module.scss'

export const ListBoxGroup = ({ item, state }) => {
  const headerRef = useRef()

  const { headingProps, groupProps } = useListBoxSection({
    heading: item.rendered,
    'aria-label': item['aria-label'],
  })

  return (
    <>
      <div role='presentation' ref={headerRef}>
        {item.rendered && <div {...headingProps}>{item.rendered}</div>}
      </div>
      <div {...groupProps}>
        {item.value.children.map(el => (
          <Option key={el.key} item={el} state={state} />
        ))}
      </div>
    </>
  )
}

const Option = ({ item, state }) => {
  const ref = useRef()
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.value },
    state,
    ref
  )

  return (
    <>
      <div
        {...optionProps}
        key={item.value}
        ref={ref}
        className={clsx(
          styles.option,
          { [styles.selected]: isSelected },
          { [styles.focused]: isFocused },
          { [styles.disabled]: isDisabled }
        )}
      >
        {item.icon && (
          <div className={styles.icon}>
            <Image src={item.icon} alt={item.label} width={24} height={24} />
          </div>
        )}
        {item.value}
      </div>
    </>
  )
}
