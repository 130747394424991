export const getMenuItemsBelow = () => {
  return [
    {
      key: ['/', 'coin', 'charts'],
      title: 'Coins',
      path: '/',
      secondaryPath: '/coin',
      withQueryParams: false,
      type: 'menuItem',
    },
    {
      key: ['exchange'],
      title: 'Exchanges',
      path: '/exchange',
      secondaryPath: '/exchange/table',
      withQueryParams: false,
      type: 'menuItem',
    },
    {
      key: ['nft'],
      title: 'NFT',
      path: '/nft',
      secondaryPath: '/nft/table',
      withQueryParams: false,
      type: 'menuItem',
    },
  ]
}
