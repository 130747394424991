import React from 'react'
import styles from './styles.module.scss'
import useAuth from 'src/hooks/useAuth'

export const EmailVerificationMessage = () => {
  const { isAuth, emailVerified } = useAuth()

  return isAuth && !emailVerified ? (
    <div className={styles.container}>
      <div className={styles.message}>
        <h2>Please, check your email an verify your account</h2>
      </div>
    </div>
  ) : null
}
