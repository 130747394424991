import cloneDeep from 'lodash/cloneDeep'
import { ENTITY, getDefaultPeriod } from 'src/consts'
import { getFieldOfDataObject } from 'src/utils/currency'
import {
  deleteUrlSearchParameter,
  updateUrlWithNewSearchParameter,
} from 'src/utils/router'
import { getSubtractDays } from 'src/utils/time/moment.helper'
import { coinsFiltersDefaults } from 'src/redux/reducers'
import { getRemoteMapData } from 'src/redux/thunks'
import { getCoinsExceptions, getMapDataMaxValues } from 'src/redux/selectors'
import {
  setCoinsInitialStateAction,
  setCoinsPeriodAction,
  deleteExceptionAction,
  resetCoinsExceptionsAction,
  addExceptionAction,
  addExceptionListAction,
  setCoinsGroupAction,
  setCoinsDependsOnAction,
  setCoinsTopAction,
  setCoinsDisplayAction,
  setCoinsRangeAction,
  setExchangesGroupAction,
  setCoinsRankingAction,
  setCoinsTagAction,
} from 'src/redux/actions'
import { combinedAnalytics } from 'src/utils/common'

export const setCoinsDisplay = data => dispatch => {
  dispatch(setCoinsDisplayAction(data))
  updateUrlWithNewSearchParameter('display', data)
  combinedAnalytics(
    `Filter`,
    'Filter',
    'Interaction:Map/Table',
    `Display Map:${data}`
  )
}

export const setCoinsTop = data => dispatch => {
  dispatch(setCoinsTopAction(data))
  updateUrlWithNewSearchParameter('slice', data)
  combinedAnalytics(`Filter`, 'Filter', 'Interaction:Map/Table', `Top:${data}`)
}

export const setCoinsRange =
  (range, url = true) =>
  dispatch => {
    dispatch(setCoinsRangeAction(range))
    updateUrlWithNewSearchParameter('range', url ? `[${range}]` : undefined)
    combinedAnalytics(
      `Filter`,
      'Filter',
      'Interaction:Map/Table',
      `Market Cap Range ${range[0]}-${range[1]}`
    )
  }

export const resetCoinsRange = range => dispatch => {
  dispatch(setCoinsRangeAction(range))
  deleteUrlSearchParameter('range')
}

export const setCoinsDependsOn =
  (data, url = true) =>
  (dispatch, getState) => {
    const currentState = getState()

    dispatch(setCoinsDependsOnAction(data))
    dispatch(
      setCoinsRange([
        0,
        getFieldOfDataObject(
          getMapDataMaxValues(currentState),
          data,
          Math.ceil
        ),
      ])
    )
    combinedAnalytics(
      `Filter`,
      'Filter',
      'Interaction:Map/Table',
      `Block size:${data}`
    )

    if (url) {
      updateUrlWithNewSearchParameter('dependsOn', data)
    }
  }

export const setGroupByEntity = group => (dispatch, getState) => {
  const currentEntity = getState().ui.currentEntity
  const setGroup =
    currentEntity === ENTITY.COIN
      ? setCoinsGroupAction
      : setExchangesGroupAction

  dispatch(setGroup(group))

  updateUrlWithNewSearchParameter('group', `${group}`)
  combinedAnalytics(
    `Filter`,
    'Filter',
    'Interaction:Map/Table',
    `Group:${group}`
  )
}

export const addException = data => (dispatch, getState) => {
  const currentExceptions = getCoinsExceptions(getState())

  if (Array.isArray(data)) {
    dispatch(addExceptionListAction(data))
    updateUrlWithNewSearchParameter(
      'exceptions',
      `[${[...currentExceptions, [...data]].join()}]`
    )
  } else {
    dispatch(addExceptionAction(data))
    updateUrlWithNewSearchParameter(
      'exceptions',
      `[${[...currentExceptions, data].join()}]`
    )
  }
}

export const deleteException = data => (dispatch, getState) => {
  const currentExceptions = getCoinsExceptions(getState())

  dispatch(deleteExceptionAction(data))
  updateUrlWithNewSearchParameter(
    'exceptions',
    `[${currentExceptions.filter(i => i !== data).join()}]`
  )
}

export const resetCoinsExceptions = () => dispatch => {
  dispatch(resetCoinsExceptionsAction())
  updateUrlWithNewSearchParameter('exceptions', '[]')
}

export const changeCoinsPeriod = updatedPeriod => dispatch => {
  const newPeriod = { ...updatedPeriod }

  if (newPeriod.start >= newPeriod.end) {
    newPeriod.start = +getSubtractDays(newPeriod.end, 1)
  }

  dispatch(setCoinsPeriodAction(newPeriod))
  updateUrlWithNewSearchParameter(
    'period',
    newPeriod.active === 'custom'
      ? `[${newPeriod.start},${newPeriod.end}]`
      : newPeriod.active
  )
}

export const setCoinsRanking = data => dispatch => {
  dispatch(setCoinsRankingAction(data))
  updateUrlWithNewSearchParameter('ranking', data)
  combinedAnalytics(
    `Filter`,
    'Filter',
    'Interaction:Map/Table',
    `Ranking:${data}`
  )
}

export const setCoinsTag = data => dispatch => {
  dispatch(setCoinsTagAction(data))
  updateUrlWithNewSearchParameter('tag', data)
  combinedAnalytics(`Filter`, 'Filter', 'Interaction:Map/Table', `Tag:${data}`)
}

export const resetCoinsFiltersAll = () => (dispatch, getState) => {
  updateUrlWithNewSearchParameter('', 'reset')

  const newState = cloneDeep(coinsFiltersDefaults)
  newState.period = getDefaultPeriod()
  newState.range = [0, getMapDataMaxValues(getState()).marketCap]

  dispatch(setCoinsInitialStateAction(newState))

  dispatch(
    getRemoteMapData({
      entity: ENTITY.COIN,
      forceBaseAndRangeReset: true,
    })
  )
}
