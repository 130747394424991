import isNaN from 'lodash/isNaN'
import isFinite from 'lodash/isFinite'
import numeral from 'numeral'

export const isNumber = value => {
  const toCheck = typeof value === 'string' ? value.replace(/,/g, '') : value

  return !isNaN(toCheck) && isFinite(toCheck)
}

export const formatChangeValue = (priceChange, price) => {
  if (numeral(priceChange).format('0,0.00') === '0.00' && price === 0) {
    return '?'
  } else if (numeral(priceChange).format('0,0.00') === 'NaN') {
    // small exponential number
    return '0.00'
  } else {
    return numeral(priceChange).format('0,0.00')
  }
}
