export const SITENAME = process.env.NEXT_PUBLIC_HOSTNAME
export const WEBSITE = process.env.NEXT_PUBLIC_BASE_URL

export const NAMINGS = {
  domainForward: 'Coin360.com',
  staticPrefix: 'coin360',
  privacyEmail: `contact@${SITENAME}`,
  socialsNickname: '@COIN360com',
}

export const MAIN_SITE_IMAGE = `${WEBSITE}/assets/share/index.png`
