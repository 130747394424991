import { ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs'
import NextError from 'next/error'
import { ReactNode, useEffect, useRef } from 'react'

type ErrorBundaryRefreshProps = {
  children: ReactNode
  /** Refresh interval in seconds  */
  refreshInterval?: number
}

export const ErrorBoundaryRefresh = ({
  children,
  refreshInterval = 60,
}: ErrorBundaryRefreshProps) => {
  const timeout = useRef<NodeJS.Timeout>(null)

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
    }
  })

  const onError = () => {
    if (!window) return
    if (timeout) clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      window.location.reload()
    }, refreshInterval * 1000)
  }

  return (
    <SentryErrorBoundary
      fallback={() => <NextError statusCode={0} />}
      onError={onError}
    >
      {children}
    </SentryErrorBoundary>
  )
}
