export const newsSources = {
  newsLine: {
    coin360: { count: 3, fullName: 'COIN360' },
    cointelegraph: { count: 6, fullName: 'Cointelegraph' },
    coindesk: { count: 6, fullName: 'Coindesk' },
    ambcrypto: { count: 4, fullName: 'AMBCrypto' },
    theblockcrypto: { count: 5, fullName: 'The Block - Crypto' },
    nulltx: { count: 2, fullName: 'Nulltx' },
    ethereumworldnews: { count: 4, fullName: 'Ethereum World News' },
    forbes: { count: 2, fullName: 'Forbes Digital Assets' },
    forbes_benjaminpirus: {
      count: 2,
      fullName: 'Benjamin Pirus, Forbes',
    },
    cryptosrus: { count: 10, fullName: 'CryptosRus' },
    coinotag: { count: 10, fullName: 'Coinotag' },
    blockchainreporter: { count: 10, fullName: 'BlockchainReporter' },
  },
  newsList: {
    coin360: { count: 6, fullName: 'COIN360' },
    cointelegraph: { count: 12, fullName: 'Cointelegraph' },
    coindesk: { count: 7, fullName: 'Coindesk' },
    ambcrypto: { count: 4, fullName: 'AMBCrypto' },
    theblockcrypto: { count: 10, fullName: 'The Block - Crypto' },
    nulltx: { count: 4, fullName: 'Nulltx' },
    ethereumworldnews: { count: 4, fullName: 'Ethereum World News' },
    forbes: { count: 3, fullName: 'Forbes Digital Assets' },
    forbes_benjaminpirus: {
      count: 3,
      fullName: 'Benjamin Pirus, Forbes',
    },
    cryptosrus: { count: 10, fullName: 'CryptosRus' },
    coinotag: { count: 10, fullName: 'Coinotag' },
    blockchainreporter: { count: 10, fullName: 'BlockchainReporter' },
  },
  page: {
    coin360: 10,
    cointelegraph: 10,
    coindesk: 10,
    cryptonomist: 10,
    'u.today': 10,
    cryptodaily: 10,
    bitcoinexchangeguide: 10,
    zycrypto: 10,
    cryptopotato: 10,
    zerocrypted: 10,
    nulltx: 10,
    VF: 10,
    ethereumworldnews: 10,
    forbes: 10,
    forbes_benjaminpirus: 10,
    cryptosrus: 10,
  },
}
