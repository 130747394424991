import { Loader } from 'src/components/Loader'
import { ElementType, ReactNode } from 'react'
import { AutoSizer } from 'react-virtualized'
import { FiltersWrapper } from 'src/elements'
import { Status } from 'src/state'
import styles from './styles.module.scss'
import { NoDataPage } from 'src/components/NoDataPage'

export type HeatViewProps = {
  filters: ReactNode
  data?: any
  HeatMap: ElementType
  filterVisibility: boolean
  filterToggle: (val: boolean) => void
  status?: Status
  isShare?: boolean
}

export const HeatView = ({
  filters,
  data,
  HeatMap,
  filterVisibility,
  filterToggle,
  status,
  isShare,
}: HeatViewProps) => {
  const isLoading = status === 'loading' && !data

  return (
    <div className={styles.wrapper}>
      {!isShare && (
        <FiltersWrapper
          filterVisibility={filterVisibility}
          filterToggle={filterToggle}
        >
          {filters}
        </FiltersWrapper>
      )}
      <div className={styles.map}>
        {isLoading ? (
          <Loader isActive={isLoading} />
        ) : data?.data.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => {
              return <HeatMap width={width} height={height} data={data} />
            }}
          </AutoSizer>
        ) : (
          <NoDataPage />
        )}
      </div>
    </div>
  )
}
