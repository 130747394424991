import EventMaster from 'src/utils/EventMaster'
import { combinedAnalytics } from 'src/utils/common'

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE'
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN'
export const ZOOM_CONTROLS_ZOOM_IN = 'ZOOM_CONTROLS_ZOOM_IN'
export const ZOOM_CONTROLS_ZOOM_OUT = 'ZOOM_CONTROLS_ZOOM_OUT'
export const SET_NEWS_STATE = 'SET_NEWS_STATE'
export const SET_CURRENT_ENTITY = 'SET_CURRENT_ENTITY'
export const SET_BANNER_VISIBILITY = 'SET_BANNER_VISIBILITY'

export const setViewType = payload => ({
  type: SET_VIEW_TYPE,
  payload,
})

export const toggleFullScreen = payload => ({
  type: TOGGLE_FULL_SCREEN,
  payload,
})

export const zoomIn = () => {
  EventMaster.emit(ZOOM_CONTROLS_ZOOM_IN)

  combinedAnalytics('Zoom', 'Zoom', 'Click', 'Plus')
  return {
    type: ZOOM_CONTROLS_ZOOM_IN,
  }
}

export const zoomOut = () => {
  EventMaster.emit(ZOOM_CONTROLS_ZOOM_OUT)

  combinedAnalytics('Zoom', 'Zoom', 'Click', 'Minus')
  return {
    type: ZOOM_CONTROLS_ZOOM_OUT,
  }
}

export const setNewsState = payload => ({
  type: SET_NEWS_STATE,
  payload,
})

export const setCurrentEntity = payload => ({
  type: SET_CURRENT_ENTITY,
  payload,
})

export const setBannerVisibility = isVisible => ({
  type: SET_BANNER_VISIBILITY,
  payload: isVisible,
})
