import styles from './styles.module.scss'
import clsx from 'clsx'
import Image from 'next/image'

export const Checkbox = ({
  checked,
  label,
  onClick = undefined,
  tooltip = undefined,
  className = undefined,
  ...props
}) => {
  return (
    <label className={clsx(styles.checkboxWrapper, className)}>
      <input
        className={styles.input}
        type='checkbox'
        {...props}
        onClick={onClick}
      />
      <div
        className={clsx(styles.checkmarkBorder, { [styles.active]: checked })}
      >
        <Image
          src='/assets/icons/icon-checkbox-active.svg'
          className={clsx(styles.checkmark, { [styles.show]: checked })}
          width='16px'
          height='16px'
          alt='Checkmark icon'
        />
      </div>
      <span className={styles.label}>{label}</span>
      {tooltip}
    </label>
  )
}

export default Checkbox
