export const fitTextFontSizeNft = ({ width, height, maxFontSize }) => {
  if (!width) return 0
  let fontSize = (width + height) / 20

  if (maxFontSize && fontSize > maxFontSize) {
    fontSize = maxFontSize
  }

  return Math.floor(fontSize)
}
