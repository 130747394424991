import { API_ENDPOINT } from 'src/consts/env'
import Router from 'next/router'
import queryString from 'query-string'
import { filteredNFTParams } from '../filters'

/** Util what should be used in all services to decorate the request URLs.
 * Reason: to make it possible to use the same API services from the server and client sides.
 * From client side we're proxying all API requests to our backend
 */
export const decorateRequestUrl = (endpoint: string): string => {
  if (typeof window === 'undefined') return `${API_ENDPOINT}${endpoint}`

  return endpoint
}

export const simplifyUrl = (originalUrl: string): string => {
  if (!(originalUrl && typeof originalUrl === 'string')) {
    return originalUrl
  }

  let simplifiedUrl = originalUrl.replace(/^https?:\/\//gi, '')
  const slashIndex = simplifiedUrl.indexOf('/')

  simplifiedUrl = simplifiedUrl.slice(
    0,
    slashIndex === -1 ? simplifiedUrl.length : slashIndex
  )

  return simplifiedUrl
}

export const updateUrlWithNewSearchParameter = (
  param: string,
  value: string
) => {
  if (typeof window !== 'undefined') {
    if (value === 'reset') {
      return Router.push(``, undefined, { shallow: true })
    } else {
      const prevParam = Router.query

      const newUrlObject = {
        ...prevParam,
        [param]: value,
      }

      const newSearchString = queryString.stringify(newUrlObject)

      Router.push(`?${decodeURI(newSearchString)}`, undefined, {
        shallow: true,
      })
    }
  }
}

export const updateUrlWithoutSearchParameter = (param: string) => {
  if (typeof window !== 'undefined') {
    const prevParam = Router.query

    const newUrlObject = {
      ...prevParam,
    }

    delete newUrlObject[param]

    const newSearchString = decodeURI(queryString.stringify(newUrlObject))

    Router.push(
      newSearchString.length ? `?${newSearchString}` : ``,
      undefined,
      {
        shallow: true,
      }
    )
  }
}

export const updateUrlWithArrayParams = (params: Array<any>) => {
  if (typeof window !== 'undefined') {
    const prevParam = Router.query
    let newSearchString

    params.forEach(value => {
      delete prevParam[value.param]
      newSearchString = {
        ...newSearchString,
        ...prevParam,
        [value.param]: `[${value.value}]`,
      }
    })
    const query = queryString.stringify(newSearchString)
    Router.push(`?${decodeURI(query).replace(/,/g, '&')}`, undefined, {
      shallow: true,
    })
  }
}

export const updateUrlWithArrayParamsMinMax = (params: Array<any>) => {
  if (typeof window !== 'undefined') {
    const prevParam = Router.query
    let newSearchString

    params.forEach(value => {
      delete prevParam[value.param]
      newSearchString = {
        ...newSearchString,
        ...prevParam,
        [value.min]: value.value[0],
        [value.max]: value.value[1],
      }
    })
    const query = queryString.stringify(newSearchString)
    Router.push(`?${decodeURI(query).replace(/,/g, '&')}`, undefined, {
      shallow: true,
    })
  }
}

export const addException = (value: string[]) => {
  updateUrlWithNewSearchParameter('exceptions', `[${[[...value]].join()}]`)
}

export const resetNftsExceptions = () => {
  const { query } = Router
  const params = filteredNFTParams(query)
  delete params.exceptions
  Router.push(
    `?${decodeURI(queryString.stringify(params)).replace(/,/g, '&')}`,
    undefined,
    {
      shallow: true,
    }
  )
}

const safeJSONParse = (key, value) => {
  try {
    switch (key) {
      case 'zoom':
        return JSON.parse(value)
      case 'watch':
        return []
    }
  } catch {
    return uiDefaults[key]
  }
}

export const parseUrl = (type, url) => {
  const queryObject = queryString.parse(url)

  if (queryObject[type]) {
    return safeJSONParse(type, queryObject[type])
  }

  return uiDefaults[type]
}

export const uiDefaults = {
  zoom: {},
  watch: [],
}
