import React, { useState, useRef, useEffect } from 'react'
import styles from './styles.module.scss'
import Link from 'next/link'
import useOnClickOutside from 'use-onclickoutside'
import Image from 'next/image'
import { useRouter } from 'next/router'

interface MenuItem {
  title: string
  path: string
  secondaryPath?: string
}

interface MenuBelowDropdownProps {
  menuItems: MenuItem[]
}

export const MenuBelowDropdown: React.FC<MenuBelowDropdownProps> = ({
  menuItems,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState('')
  const ref = useRef(null)
  const router = useRouter()

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleItemClick = item => {
    setSelectedItem(item)
    toggleDropdown()
  }

  const handleClose = () => setIsOpen(false)

  useOnClickOutside(ref, handleClose)

  useEffect(() => {
    const currentPath = router.pathname
    const selectedItem = menuItems.find(
      item => currentPath === item.path || currentPath === item.secondaryPath
    )
    if (selectedItem) {
      setSelectedItem(selectedItem.title)
    }
  }, [router.pathname, menuItems])

  return (
    <div className={styles.dropdown} ref={ref} onClick={toggleDropdown}>
      <p className={styles.selectedItem}>{selectedItem}</p>

      <Image
        className={styles.arrow}
        src='/assets/icons/icon-arrow-down-black.svg'
        width='10px'
        height='10px'
        alt='Arrow icon'
      />

      {isOpen && (
        <div className={styles.menu}>
          {menuItems.map((item, index) => (
            <Link key={index} href={item.path}>
              <a
                className={styles.menuItem}
                onClick={() => handleItemClick(item.title)}
              >
                {item.title}
              </a>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}
