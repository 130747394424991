import { ENTITY } from 'src/consts'
import {
  GET_MARKETS_DATA,
  GET_MARKETS_DATA_FAILURE,
  GET_MARKETS_DATA_SUCCESS,
  SET_MARKETS_CURRENCY,
} from '../actions/markets'

const initialState = {
  [ENTITY.COIN]: [],
  [ENTITY.EXCHANGE]: [],
  marketsCurrency: 'usd',
  isFetching: true,
  isFailed: false,
}

export const markets = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETS_DATA:
      return {
        ...state,
        isFetching: true,
        isFailed: false,
      }
    case GET_MARKETS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFailed: false,
      }
    case GET_MARKETS_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFailed: true,
      }
    case SET_MARKETS_CURRENCY:
      return {
        ...state,
        marketsCurrency: action.payload,
      }
    default:
      return state
  }
}

export default markets
