import { getSubtractDays, getUTCDate } from 'src/utils/time/moment.helper'
import { intl } from 'src/i18n'
import subHours from 'date-fns/subDays'

export const PERIODS = {
  keys: ['1h', '24h', '7d', '30d', 'custom'],
  keysMirror: {
    '1h': '1h',
    '24h': '24h',
    '7d': '7d',
    '30d': '30d',
    custom: 'custom',
  },
  names: {
    '1h': intl.t('constants.periods.names.1h'),
    '24h': intl.t('constants.periods.names.24h'),
    '7d': intl.t('constants.periods.names.7d'),
    '30d': intl.t('constants.periods.names.30d'),
    custom: intl.t('constants.periods.names.custom'),
  },
  dataKeys: {
    '1h': intl.t('constants.periods.dataKeys.1h'),
    '24h': intl.t('constants.periods.dataKeys.24h'),
    '7d': intl.t('constants.periods.dataKeys.7d'),
    '30d': intl.t('constants.periods.dataKeys.30d'),
    custom: '',
  },
  axisLabels: {
    '1h': intl.t('constants.periods.axisLabels.1h'),
    '24h': intl.t('constants.periods.axisLabels.24h'),
    '7d': intl.t('constants.periods.axisLabels.7d'),
    '30d': intl.t('constants.periods.axisLabels.30d'),
    custom: intl.t('constants.periods.axisLabels.custom'),
  },
}

export const getDefaultPeriod = () => ({
  // active: PERIODS.keysMirror['1h'],
  // start: +subHours(getUTCDate(), 1),
  active: PERIODS.keysMirror['24h'],
  start: +getSubtractDays(getUTCDate(), 1),
  end: +getUTCDate(),
})
