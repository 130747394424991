import { HYDRATE } from 'next-redux-wrapper'
import { getDefaultPeriod } from 'src/consts'
import {
  SET_COINS_STATE,
  SET_COINS_GROUP,
  SET_COINS_RANGE,
  SET_COINS_DISPLAY,
  SET_COINS_TOP,
  SET_COINS_DEPENDS_ON,
  ADD_COINS_EXCEPTION,
  ADD_COINS_EXCEPTION_LIST,
  DELETE_COINS_EXCEPTION,
  RESET_COINS_ALL_EXCEPTION,
  SET_COINS_PERIODS,
  SET_COINS_RANKING,
  SET_COINS_TAG,
} from 'src/redux/actions'

export const coinsFiltersDefaults = {
  display: 'all',
  top: 'all',
  dependsOn: 'marketCap',
  range: [0, 0],
  period: getDefaultPeriod(),
  exceptions: [],
  group: 'all',
  ranking: 'top100',
  tag: null,
}

export const coinsFilters = (state = coinsFiltersDefaults, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.filters.coins,
      }
    case SET_COINS_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_COINS_GROUP:
      return {
        ...state,
        group: action.payload,
      }
    case SET_COINS_RANGE:
      return {
        ...state,
        range: action.payload,
      }
    case SET_COINS_DISPLAY:
      return {
        ...state,
        display: action.payload,
      }
    case SET_COINS_TOP:
      return {
        ...state,
        top: action.payload,
      }
    case SET_COINS_DEPENDS_ON:
      return {
        ...state,
        dependsOn: action.payload,
      }
    case ADD_COINS_EXCEPTION_LIST:
      return {
        ...state,
        exceptions: [...state.exceptions, ...action.payload],
      }
    case ADD_COINS_EXCEPTION:
      return {
        ...state,
        exceptions: [...state.exceptions, action.payload],
      }
    case DELETE_COINS_EXCEPTION:
      return {
        ...state,
        exceptions: state.exceptions.filter(exc => exc !== action.payload),
      }
    case RESET_COINS_ALL_EXCEPTION:
      return {
        ...state,
        exceptions: [],
      }
    case SET_COINS_PERIODS:
      return {
        ...state,
        period: {
          active: action.payload.active,
          start: action.payload.start,
          end: action.payload.end,
        },
      }
    case SET_COINS_RANKING:
      return {
        ...state,
        ranking: action.payload,
      }
    case SET_COINS_TAG:
      return {
        ...state,
        tag: action.payload,
      }
    default:
      return { ...state }
  }
}

export default coinsFilters
