export const SET_EXCHANGES_TOP = 'SET_EXCHANGES_TOP'
export const SET_EXCHANGES_PERIODS = 'SET_EXCHANGES_PERIODS'
export const ADD_EXCHANGES_EXCEPTION = 'ADD_EXCHANGES_EXCEPTION'
export const ADD_EXCHANGES_EXCEPTION_LIST = 'ADD_EXCHANGES_EXCEPTION_LIST'
export const DELETE_EXCHANGES_EXCEPTION = 'DELETE_EXCHANGES_EXCEPTION'
export const RESET_EXCHANGES_ALL_EXCEPTION = 'RESET_EXCHANGES_ALL_EXCEPTION'
export const SET_EXCHANGES_GROUP = 'SET_EXCHANGES_GROUP'
export const SET_EXCHANGES_RANGE = 'SET_EXCHANGES_RANGE'
export const SET_EXCHANGES_MARKETS_RANGE = 'SET_EXCHANGES_MARKETS_RANGE'
export const SET_EXCHANGES_STATE = 'SET_EXCHANGES_STATE'

export const setExchangesInitialStateAction = payload => ({
  type: SET_EXCHANGES_STATE,
  payload,
})

export const setExchangesTopAction = payload => ({
  type: SET_EXCHANGES_TOP,
  payload,
})

export const setExchangesPeriodAction = payload => ({
  type: SET_EXCHANGES_PERIODS,
  payload,
})

export const addExchangesExceptionAction = payload => ({
  type: ADD_EXCHANGES_EXCEPTION,
  payload,
})

export const addExchangesExceptionListAction = payload => ({
  type: ADD_EXCHANGES_EXCEPTION_LIST,
  payload,
})

export const deleteExchangesExceptionAction = payload => ({
  type: DELETE_EXCHANGES_EXCEPTION,
  payload,
})

export const resetExchangesExceptionsAction = payload => ({
  type: RESET_EXCHANGES_ALL_EXCEPTION,
  payload,
})

export const setExchangesGroupAction = payload => ({
  type: SET_EXCHANGES_GROUP,
  payload,
})

export const setExchangesRangeAction = payload => ({
  type: SET_EXCHANGES_RANGE,
  payload,
})

export const setExchangesMarketsRangeAction = payload => ({
  type: SET_EXCHANGES_MARKETS_RANGE,
  payload,
})
