import React, { useRef } from 'react'
import Image from 'next/image'
import { useButton } from '@react-aria/button'
import { FocusScope } from '@react-aria/focus'
import { PressResponder, useHover } from '@react-aria/interactions'
import { HiddenSelect, useSelect } from '@react-aria/select'
import { mergeProps } from '@react-aria/utils'
import { useSelectState } from '@react-stately/select'
import { AriaSelectProps } from '@react-types/select'
import { Popover } from '../Popover'
import { ListBox } from '../ListBox'
import { Tray } from '../overlays'
import { useBreackpoints } from 'src/hooks/useBreackpoints'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

type SelectProps<T> = AriaSelectProps<T> & {
  label?: string
  classNameContainer?: string
  pickerClassName?: string
  popoverClassName?: string
}

export const Select = <T extends object>(props: SelectProps<T>) => {
  const state = useSelectState(props)
  const ref = useRef()
  const {
    name,
    label,
    isDisabled,
    classNameContainer,
    pickerClassName,
    popoverClassName,
  } = props

  const match = useBreackpoints('lg')

  const { triggerProps, valueProps, menuProps, labelProps } = useSelect(
    props,
    state,
    ref
  )

  const { buttonProps } = useButton(triggerProps, ref)
  const { hoverProps } = useHover({ isDisabled })

  //We put these two props together, since the onclick of the label makes focus, and that behavior caused the select to close.
  const labelInsideSelectProps = { ...labelProps, ...buttonProps }

  const listbox = (
    <FocusScope restoreFocus contain={match.lg}>
      <ListBox {...menuProps} state={state} />
    </FocusScope>
  )

  let overlay
  if (match.lg) {
    overlay = (
      <Tray isOpen={state.isOpen} onClose={state.close}>
        {listbox}
      </Tray>
    )
  } else {
    overlay = state.isOpen && (
      <Popover
        isOpen={state.isOpen}
        onClose={state.close}
        className={popoverClassName}
      >
        {listbox}
      </Popover>
    )
  }

  const { value }: any = state.selectedItem

  const picker = (
    <>
      <HiddenSelect state={state} triggerRef={ref} label={label} name={name} />
      <PressResponder {...mergeProps(hoverProps, triggerProps)}>
        <button
          {...buttonProps}
          ref={ref}
          className={clsx(styles.button, classNameContainer)}
        >
          {label && (
            <span {...labelInsideSelectProps} className={styles.label}>
              {label}
            </span>
          )}
          <span className={styles.valueWrapper} {...valueProps}>
            {state.selectedItem ? (
              <>
                {value?.icon && (
                  <div className={styles.icon}>
                    <Image
                      src={value?.icon}
                      alt={label}
                      width={24}
                      height={24}
                    />
                  </div>
                )}
                {state.selectedItem.rendered}
              </>
            ) : (
              intl.t('360ui.select.option')
            )}
          </span>
        </button>
      </PressResponder>
      {state.collection.size === 0 ? null : overlay}
    </>
  )

  return <span className={clsx(styles.picker, pickerClassName)}>{picker}</span>
}
