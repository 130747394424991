import { ReactNode, useState } from 'react'
import { useBreackpoints } from 'src/hooks'
import { useAppIntl } from 'src/i18n'
import { Collapse } from './Collapse'
import { Button, Drawer } from 'src/360ui'
import { updateUrlWithNewSearchParameter } from 'src/utils/router'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { CurrencySelect } from 'src/elements'
import ViewTypeSwitcher from 'src/components/ViewTypeSwitcher/'
import MenuBelow from 'src/components/Header/components/MenuBelow'

export type FiltersWrapperProps = {
  children: ReactNode
  filterVisibility: boolean
  filterToggle: (val: boolean) => void
}

export const FiltersWrapper = ({
  children,
  filterVisibility,
  filterToggle,
}: FiltersWrapperProps) => {
  const intl = useAppIntl()
  const [visibility, setVisibility] = useState(true)

  const match = useBreackpoints('lg')

  const renderForSmallScreen = () => {
    return (
      <div>
        <div className={styles.mobileWrapper}>
          <MenuBelow />
          <ViewTypeSwitcher headerView={false} />
        </div>
        <Drawer
          isOpen={filterVisibility}
          onClose={() => filterToggle(!filterVisibility)}
        >
          <div className={styles.header}>
            <span className={styles.back}></span>
            <span className={styles.title}>{intl.t('nft.filters.title')}</span>
            <span
              className={styles.close}
              onClick={() => filterToggle(false)}
            ></span>
          </div>
          <div className={styles.body}>{children}</div>
          <div className={styles.footer}>
            <Button
              variant='tertiary'
              onPress={() => updateUrlWithNewSearchParameter('', 'reset')}
              className={styles.btn}
            >
              {intl.t('nft.filters.reset')}
            </Button>
            <Button
              variant='primary'
              onPress={() => filterToggle(false)}
              className={styles.btn}
            >
              {intl.t('nft.filters.apply')}
            </Button>
          </div>
        </Drawer>
      </div>
    )
  }

  const renderForLargeScreen = () => {
    return (
      <div className={styles.filter}>
        <div className={styles.left}>
          <MenuBelow />
          {/* <Collapse isSelected={visibility} onChange={setVisibility} /> To hide the filters, can use that later to show the modal instead. 29/2 */}
          <div className={clsx(styles.items, { [styles.visible]: visibility })}>
            {/* {children} Filters that gonna change later to a modal. 29/2 */}
            {/* <Button
              variant='tertiary'
              className={styles.resetButton}
              onPress={() => updateUrlWithNewSearchParameter('', 'reset')}
              size='sm'
            >
              {intl.t('nft.filters.reset')}
            </Button> The reset button for filters, also gonna be used in the filter modal. 29/2 */}
          </div>
        </div>
        <div className={styles.right}>
          <ViewTypeSwitcher headerView={false} />
        </div>
      </div>
    )
  }

  return match.lg ? renderForSmallScreen() : renderForLargeScreen()
}
