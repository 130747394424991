import Image from 'next/image'
import styles from './styles.module.scss'
import { useAppIntl } from 'src/i18n'

export const NoDataPage = () => {
  const intl = useAppIntl()
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          src='/assets/nodata.svg'
          alt='Empty Data'
          width={140}
          height={140}
        />
      </div>
      <span className={styles.text}>{intl.t('noDataPage.span1')}</span>
      <span className={styles.text}>{intl.t('noDataPage.span2')}</span>
    </div>
  )
}
