import axios from 'axios'
import { batch } from 'react-redux'
import {
  API_GET_EXCHANGES_MAPDATA,
  API_GET_MAPDATA,
  API_GET_COINS_STATS,
  ENTITY,
  getEntityAdapter,
  getEntityCollectionName,
  mapEntityCollectionToEntity,
} from 'src/consts'
import {
  remoteGetMapDataSuccess,
  remoteGetMapDataError,
  setCoinsRangeAction,
  setExchangesRangeAction,
  setExchangesMarketsRangeAction,
} from 'src/redux/actions'
import {
  getCoinsDependsOn,
  getCoinsRange,
  getExchangesDependsOn,
  getExchangesRange,
  getMapDataMaxValues,
  getExchangesMapDataMaxValues,
  getCoinsPeriod,
  getSharedFiltersCurrency,
  getExchangesMarketsRange,
  getExchangesPeriod,
  getCoinsRanking,
  getCoinsTag,
} from 'src/redux/selectors'
import {
  coinsFiltersDefaults,
  exchangesFiltersDefaults,
} from 'src/redux/reducers'
import { prepareMapData } from 'src/utils/data'
import { getUTCDate } from 'src/utils/time/moment.helper'
import {
  CoinsDataResponseSchema,
  ExchangesDataResponseSchema,
  CoinsStatsDataResponseSchema,
} from 'src/validation'
import { getLocalStorageItem } from 'src/utils/localStorage'
import { returnParamFromUrl } from 'src/360ui/utils/router'

export const getRemoteMapData =
  ({ entity, forceBaseAndRangeReset = false, url = '' }) =>
  async (dispatch, getState) => {
    const currentState = getState()

    const currency =
      returnParamFromUrl('currency') ||
      getLocalStorageItem('currency') ||
      getSharedFiltersCurrency(currentState)

    // TODO change using redux
    const shortPeriod = returnParamFromUrl('short_period')

    const storePeriod =
      entity === ENTITY.COIN
        ? getCoinsPeriod(currentState)
        : getExchangesPeriod(currentState)

    let period = {
      period: storePeriod.active,
    }

    if (storePeriod.active === 'custom') {
      period = {
        ...period,
        start: Math.floor(getUTCDate(storePeriod.start).valueOf() / 1000),
        end: Math.floor(getUTCDate(storePeriod.end).valueOf() / 1000),
      }
    }

    const ranking =
      entity === ENTITY.COIN ? getCoinsRanking(currentState) : undefined

    const tag = entity === ENTITY.COIN ? getCoinsTag(currentState) : undefined

    const ENTITY_API_URL =
      entity === ENTITY.COIN ? API_GET_MAPDATA : API_GET_EXCHANGES_MAPDATA
    const validationSchema =
      entity === ENTITY.COIN
        ? CoinsDataResponseSchema
        : ExchangesDataResponseSchema

    try {
      const requestParams = {
        currency,
        ...period,
        ranking,
        tag,
        short_period: shortPeriod,
      }

      /* TODO if (
        entity === ENTITY.COIN &&
        !!currentState?.mapdata?.coins?.lastUpdate
      ) {
        const lastUpdate = currentState.mapdata.coins.lastUpdate
        if (!isFullUpdateRequired(lastUpdate, requestParams)) {
          await updateCoinsStats(currentState.mapdata.coins, url, requestParams)

          const newFiltersBaseRange = updateFiltersBaseRange(
            currentState,
            forceBaseAndRangeReset
          )

          batch(() => {
            dispatch(setCoinsRangeAction(newFiltersBaseRange.range))
            dispatch(remoteGetMapDataSuccess(currentState.mapdata))
          })

          fetchEnd()
          return
        }
      }
       */

      const response = await axios.get(`${url}${ENTITY_API_URL}`, {
        params: requestParams,
      })

      // Because yup can't detect an empty object as a separate error
      if (Object.keys(response.data).length === 0) {
        throw new Error('Error fetching data')
      }

      validationSchema.validate(response.data).catch(error => {
        throw new Error(error)
      })

      const entityAdapter = getEntityAdapter(entity)
      const jsonResponse = entityAdapter(response.data)

      const entityCollectionName = getEntityCollectionName(entity)
      const dataToDispatch = {
        [entityCollectionName]: jsonResponse,
      }
      const newFiltersBaseRangeAndMarkets = {
        [ENTITY.COIN]: {
          base: getCoinsDependsOn(currentState),
          range: getCoinsRange(currentState),
        },
        [ENTITY.EXCHANGE]: {
          base: getExchangesDependsOn(currentState),
          range: getExchangesRange(currentState),
          markets: getExchangesMarketsRange(currentState),
        },
      }

      const maxDataSelector = {
        [ENTITY.COIN]: getMapDataMaxValues,
        [ENTITY.EXCHANGE]: getExchangesMapDataMaxValues,
      }

      // Bootstrap of mapdata fetching
      Object.keys(dataToDispatch).forEach(entityCollection => {
        if (mapEntityCollectionToEntity(entityCollection)) {
          const entity = mapEntityCollectionToEntity(entityCollection)
          const max = maxDataSelector[entity](currentState) || {}
          const maxRangeValueByEntityAndBaseValue = Math.ceil(
            max[newFiltersBaseRangeAndMarkets[entity].base]
          )

          if (
            newFiltersBaseRangeAndMarkets[entity].range[0] === 0 &&
            newFiltersBaseRangeAndMarkets[entity].range[1] ===
              maxRangeValueByEntityAndBaseValue
          ) {
            const entityData = dataToDispatch[entityCollection]
            if (entityData && entityData.max) {
              const baseByEntity = newFiltersBaseRangeAndMarkets[entity].base
              newFiltersBaseRangeAndMarkets[entity].range = [
                0,
                Math.ceil(entityData.max[baseByEntity]),
              ]
            }
          }

          if (entity === ENTITY.EXCHANGE) {
            if (
              newFiltersBaseRangeAndMarkets[entity]?.markets[0] === 0 &&
              newFiltersBaseRangeAndMarkets[entity]?.markets[1] ===
                maxRangeValueByEntityAndBaseValue
            ) {
              const entityData = dataToDispatch[entityCollection]
              if (entityData && entityData.max) {
                const baseByEntity = 'pairs'
                newFiltersBaseRangeAndMarkets[entity].markets = [
                  0,
                  Math.ceil(entityData.max[baseByEntity]),
                ]
              }
            }
          }
        }
      })

      if (forceBaseAndRangeReset) {
        newFiltersBaseRangeAndMarkets[entity].base = {
          [ENTITY.COIN]: coinsFiltersDefaults.dependsOn,
          [ENTITY.EXCHANGE]: exchangesFiltersDefaults.dependsOn,
        }[entity]

        newFiltersBaseRangeAndMarkets[entity].range = [
          0,
          Math.ceil(
            maxDataSelector[entity]({ mapdata: dataToDispatch })[
              newFiltersBaseRangeAndMarkets[entity].base
            ]
          ),
        ]

        if (entity === ENTITY.EXCHANGE) {
          if (newFiltersBaseRangeAndMarkets[entity].markets) {
            newFiltersBaseRangeAndMarkets[entity].markets = [
              0,
              Math.ceil(
                maxDataSelector[entity]({ mapdata: dataToDispatch })['pairs']
              ),
            ]
          }
        }
      } else {
        Object.keys(dataToDispatch).forEach(entityCollection => {
          if (mapEntityCollectionToEntity(entityCollection)) {
            const entity = mapEntityCollectionToEntity(entityCollection)

            const max = maxDataSelector[entity](currentState) || {}

            const maxRangeValueByEntityAndBaseValue = Math.ceil(
              max[newFiltersBaseRangeAndMarkets[entity].base]
            )
            // We don't want to reset the range for the entity if a user has changed it
            if (
              maxRangeValueByEntityAndBaseValue &&
              !(
                newFiltersBaseRangeAndMarkets[entity].range[0] !== 0 ||
                newFiltersBaseRangeAndMarkets[entity].range[1] !==
                  maxRangeValueByEntityAndBaseValue
              )
            ) {
              const entityData = dataToDispatch[entityCollection]
              if (entityData && entityData.max) {
                const baseByEntity = newFiltersBaseRangeAndMarkets[entity].base
                newFiltersBaseRangeAndMarkets[entity].range = [
                  0,
                  Math.ceil(entityData.max[baseByEntity]),
                ] // TODO: complex case
              }
            }
          }
        })
      }

      // TODO if (entity === ENTITY.COIN) {
      //   dataToDispatch.coins.lastUpdate = {
      //     time: Date.now(),
      //     requestParams,
      //   }
      // }

      // Set ranges and map data using "batch dispatch" to prevent heatmap "blink"
      if (entity === ENTITY.EXCHANGE) {
        batch(() => {
          dispatch(
            setExchangesRangeAction(
              newFiltersBaseRangeAndMarkets[ENTITY.EXCHANGE].range
            )
          )
          dispatch(
            setExchangesMarketsRangeAction(
              newFiltersBaseRangeAndMarkets[ENTITY.EXCHANGE].markets
            )
          )
          dispatch(remoteGetMapDataSuccess(dataToDispatch))
        })
      } else if (entity === ENTITY.COIN) {
        batch(() => {
          dispatch(
            setCoinsRangeAction(
              newFiltersBaseRangeAndMarkets[ENTITY.COIN].range
            )
          )
          dispatch(remoteGetMapDataSuccess(dataToDispatch))
        })
      }
    } catch (error) {
      dispatch(remoteGetMapDataError(error.message))
    }

    fetchEnd()
  }

function isFullUpdateRequired(lastUpdate, requestParams) {
  const seconds = (Date.now() - lastUpdate.time) / 1000
  if (seconds > 1800 /* 30minutes */) {
    return true
  }

  return !compareKeys(lastUpdate.requestParams, requestParams)
}

async function updateCoinsStats(coins, url, requestParams) {
  const response = await axios.get(`${url}${API_GET_COINS_STATS}`, {
    params: requestParams,
  })

  if (Object.keys(response.data).length === 0) {
    throw new Error('Error fetching data')
  }

  CoinsStatsDataResponseSchema.validate(response.data).catch(error => {
    throw new Error(error)
  })

  const data = prepareMapData(response.data)

  coins.data.map(coin => {
    const stat = data.dict[coin.slug]
    if (!!stat) {
      updateCoinStat(coin, stat)
    }
  })

  updateCoinStat(coins.min, data.min)
  updateCoinStat(coins.max, data.max)
}

function updateFiltersBaseRange(currentState, forceBaseAndRangeReset) {
  const coins = currentState.mapdata.coins

  const filtersBaseRange = {
    base: getCoinsDependsOn(currentState),
    range: getCoinsRange(currentState),
  }

  const max = getMapDataMaxValues(currentState) || {}
  const maxRangeValueAndBaseValue = Math.ceil(max[filtersBaseRange.base])

  if (forceBaseAndRangeReset) {
    filtersBaseRange.base = coinsFiltersDefaults.dependsOn
    filtersBaseRange.range = [0, maxRangeValueAndBaseValue]
  } else if (
    maxRangeValueAndBaseValue &&
    !(
      filtersBaseRange.range[0] !== 0 ||
      filtersBaseRange.range[1] !== maxRangeValueAndBaseValue
    ) &&
    coins?.max
  ) {
    filtersBaseRange.range = [0, Math.ceil(coins.max[filtersBaseRange.base])]
  }

  return filtersBaseRange
}

function updateCoinStat(coin, stat) {
  coin.change = stat.change
  coin.marketCap = stat.marketCap
  coin.marketCapRank = stat.marketCapRank
  coin.price = stat.price
  coin.volume = stat.volume
}

function compareKeys(obj1, obj2) {
  for (const param of Object.keys(obj1)) {
    if (obj1[param] !== obj2[param]) return false
  }

  for (const param of Object.keys(obj2)) {
    if (obj1[param] !== obj2[param]) return false
  }

  return true
}

function fetchEnd() {
  if (typeof window !== 'undefined') {
    window.autoUpdater?.fetchEnd()
  }
}
