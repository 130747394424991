import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import { getCompleteMapDataTree } from 'src/core/getCompleteMapDataTree'
import { buildTreeMap, buildExchangesTreeMap } from 'src/core/buildTreeMap'
import {
  getDependsOnByEntity,
  getCoinsDisplay,
  getCoinsTopParsed,
  getCoinsRange,
  getCoinsDependsOn,
  getCoinsExceptionsDict,
  getCoinsGroup,
  getCoinsRanking,
  getCoinsTag,
  getExchangesExceptionsDict,
  getExchangesRange,
  getExchangesDependsOn,
  getExchangesGroup,
  getExchangesMarketsRange,
  getExchangesTop,
} from './filters'
import { EXCHANGES_TOP_LOSERS_AND_GAINERS } from 'src/consts'

export const getMapDataFromStore = createSelector(
  state => state.mapdata,
  m => m.coins
)

export const getExchangesMapDataFromStore = createSelector(
  state => state.mapdata,
  m => m.exchanges
)

export const getCoinsCount = createSelector(
  [getMapDataFromStore, getExchangesMapDataFromStore],
  (coins, exchanges) => {
    return {
      coinsTotal: coins.data.length,
      exchangesTotal: exchanges.data.length,
    }
  }
)

export const getCoinsDict = createSelector(getMapDataFromStore, d => d.dict)

export const getExchangesDict = createSelector(
  getExchangesMapDataFromStore,
  d => d.dict
)

export const getGlobals = createSelector(getMapDataFromStore, m => m?.global)

export const getProtocols = createSelector(
  getMapDataFromStore,
  m => m.protocols
)

export const getLastUpdateTimestamp = createSelector(
  getMapDataFromStore,
  m => m.timestamp
)

export const getMapDataMaxValues = createSelector(getMapDataFromStore, m =>
  m.max ? { ...m.max } : null
)

export const getGlobalsExchanges = createSelector(
  getExchangesMapDataFromStore,
  m => m.global
)

export const getExchangesMapDataMaxValues = createSelector(
  getExchangesMapDataFromStore,
  m => (m.max ? { ...m.max } : null)
)

export const getMapDataArrayRaw = createSelector(getMapDataFromStore, m =>
  sortBy(
    m.data.filter(el => el.category !== 'fiat') || [],
    d => d.marketCapRank
  )
)

export const getMapDataArrayRawSlugs = createSelector(getMapDataArrayRaw, m =>
  m.map(i => ({ value: i.slug, label: i.symbol }))
)

export const getExchangesMapDataArrayRaw = createSelector(
  getExchangesMapDataFromStore,
  m =>
    sortBy(m.data || [], [
      d => d.type !== 'Verified',
      d => -d.volumeReported,
    ]).map((item, index) => ({
      ...item,
      index: index + 1,
    }))
)

export const getFilteredMapDataArray = createSelector(
  [
    getMapDataArrayRaw,
    getCoinsDisplay,
    getCoinsTopParsed,
    getCoinsRange,
    getCoinsDependsOn,
    getCoinsExceptionsDict,
    getCoinsGroup,
    getCoinsRanking,
    getCoinsTag,
    getProtocols,
  ],
  (
    unfilteredMapDataArray,
    category,
    topSliceParsed,
    range,
    base,
    exceptionsDict,
    group,
    ranking,
    tag,
    protocols
  ) => {
    const topCfx = { losers: 1, gainers: -1, all: 0 }[topSliceParsed.type]

    let newFilteredArray = unfilteredMapDataArray
      .map((i, index) => ({ ...i, index: index + 1 }))
      .reduce((acc, item) => {
        if (
          (category !== 'all' && item.category !== category.slice(0, -1)) ||
          exceptionsDict[item.slug] ||
          item[base] < range[0] ||
          item[base] > range[1] ||
          (topCfx === 1 && item.change >= 0) ||
          (topCfx === -1 && item.change <= 0) ||
          (group !== 'all' &&
            group != null &&
            protocols[group] &&
            item.protocol !== group)
        ) {
          return acc
        }

        acc.push(item)
        return acc
      }, [])

    newFilteredArray =
      topCfx !== 0
        ? sortBy(newFilteredArray, d => topCfx * d.change)
            .filter(({ price }) => price > 0)
            .slice(0, topSliceParsed.value)
        : newFilteredArray

    return newFilteredArray
  }
)

export const getFilteredExchangesArray = createSelector(
  [
    getExchangesMapDataArrayRaw,
    getExchangesTop,
    getExchangesExceptionsDict,
    getExchangesRange,
    getExchangesDependsOn,
    getExchangesGroup,
    getExchangesMarketsRange,
  ],
  (raw, losersAndGainers, exceptions, range, base, group, markets) => {
    const topCfx =
      losersAndGainers === 'losers50'
        ? 1
        : losersAndGainers === 'gainers50'
        ? -1
        : 0
    const changeField = { volumeReported: 'changeReported', volume: 'change' }[
      base
    ]
    /* Exceptions & range filter */
    let filtered = raw.filter(i => {
      return (
        !exceptions[i.slug] &&
        (!range ||
          !range.length ||
          (i[base] >= range[0] && i[base] <= range[1])) &&
        (!markets ||
          !markets.length ||
          (i.pairs >= markets[0] && i.pairs <= markets[1])) &&
        (!group ||
          group === 'all' ||
          i.type === group ||
          (!i.type && group === 'Unknown')) &&
        ((topCfx === 1 && i[changeField] < 0) ||
          (topCfx === -1 && i[changeField] > 0) ||
          topCfx === 0)
      )
    })

    filtered =
      topCfx !== 0
        ? sortBy(filtered, d => topCfx * d.change).slice(
            0,
            EXCHANGES_TOP_LOSERS_AND_GAINERS.amountOfTopSymbols[
              losersAndGainers
            ]
          )
        : filtered

    return filtered.length ? filtered : null
  }
)

export const getFilteredMapDataTree = createSelector(
  [getMapDataFromStore, getFilteredMapDataArray],
  buildTreeMap
)

export const getFilteredExchangesDataTree = createSelector(
  [getExchangesMapDataFromStore, getFilteredExchangesArray],
  buildExchangesTreeMap
)

const getMapDataProps = (
  _,
  {
    filteredTree,
    height,
    width,
    isMobile,
    onWatchlist,
    entity,
    topLoosersAndGainers,
  }
) => ({
  filteredTree,
  height,
  width,
  isMobile,
  onWatchlist,
  entity,
  topLoosersAndGainers,
})

export const getTreeMapCalculatedData = createSelector(
  [getDependsOnByEntity, getMapDataProps],
  (
    baseValue,
    {
      filteredTree,
      height,
      width,
      isMobile,
      onWatchlist,
      entity,
      topLoosersAndGainers,
    }
  ) => {
    return !(filteredTree && filteredTree.children)
      ? { leaves: [], groups: [] }
      : getCompleteMapDataTree({
          baseValue,
          filteredTree,
          height,
          width,
          isMobile,
          onWatchlist,
          entity,
          topLoosersAndGainers,
        })
  }
)
export const getMapDataSelector = ({ mapdata }) => mapdata

export const getCoinsGlobalSelector = ({ mapdata }) =>
  mapdata.coins.global.marketCap > 0
    ? mapdata.coins.global
    : mapdata.exchanges.global

export const getExchangesGlobalSelector = ({ mapdata }) =>
  mapdata.exchanges.global

// TODO

export const getTableViewData = getFilteredMapDataArray

export const getTableViewDataExchanges = getFilteredExchangesArray
