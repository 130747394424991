import { useCallback, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import clsx from 'clsx'
import Image from 'next/image'
import { Button } from 'src/360ui'
import { CURRENCY_SELECT_OPTIONS } from 'src/consts'
import { updateUrlWithNewSearchParameter } from 'src/360ui/utils/router'
import styles from './styles.module.scss'

export const DesktopView = ({ selectedKey }) => {
  const [isOpen, setOpen] = useState(false)
  const ref = useRef()

  const toggleOpen = () => setOpen(!isOpen)

  const handleClose = () => setOpen(false)

  useOnClickOutside(ref, handleClose)

  const handleSelect = useCallback(value => {
    updateUrlWithNewSearchParameter('currency', value)
    handleClose()
  }, [])

  return (
    <div className={styles.container}>
      <Button variant='tertiary' onPress={toggleOpen} className={styles.btn}>
        <Image
          src={`/assets/currency/${selectedKey}-filter-basecurrency.svg`}
          alt={selectedKey}
          width={16}
          height={16}
        />
        <span className={styles.label}>{selectedKey}</span>
      </Button>
      <div
        className={clsx(styles.wrapper, { [styles.isOpen]: isOpen })}
        ref={ref}
      >
        {CURRENCY_SELECT_OPTIONS.map(item => (
          <div key={item.name} className={styles.group}>
            {item.children.map(child => (
              <div
                key={child.value}
                className={clsx(styles.option, {
                  [styles.selectedOption]: child.value === selectedKey,
                })}
                onClick={() => handleSelect(child.value)}
              >
                <Image
                  src={child.icon}
                  alt={child.label}
                  width={16}
                  height={16}
                />
                <span className={styles.optionLabel}>{child.value}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
