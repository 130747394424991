export const REFRESH_EXCHANGE_DATA = 'REFRESH_EXCHANGE_DATA'
export const SET_EXCHANGE_DATA = 'SET_EXCHANGE_DATA'
export const SET_EXCHANGE_DATA_ERROR = 'SET_EXCHANGE_DATA_ERROR'

export const refreshExchangeData = payload => ({
  type: REFRESH_EXCHANGE_DATA,
  payload,
})

export const setExchangeData = payload => ({
  type: SET_EXCHANGE_DATA,
  payload,
})

export const setExchangeDataError = payload => ({
  type: SET_EXCHANGE_DATA_ERROR,
  payload,
})
