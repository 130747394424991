import Router from 'next/router'
import queryString from 'query-string'

export const updateUrlWithNewSearchParameter = (
  param: string,
  value: string
) => {
  if (typeof window !== 'undefined') {
    if (value === 'reset') {
      return Router.push(``, undefined, { shallow: true })
    } else {
      const prevParam = Router.query

      const newUrlObject = {
        ...prevParam,
        [param]: value,
      }

      const newSearchString = queryString.stringify(newUrlObject)

      Router.push(`?${decodeURI(newSearchString)}`, undefined, {
        shallow: true,
      })
    }
  }
}

export const returnParamFromUrl = (param: string) => {
  if (typeof window !== 'undefined') {
    return Router.query[param]
  }
}
