import { array, number, object, string } from 'yup'
import { GlobalSchema } from './common'

const CoinSchema = object().shape({
  c: string(),
  ch: number(),
  cs: number(),
  em: number(),
  mc: number(),
  mcr: number(),
  n: string(),
  p: number(),
  pb: number(),
  pr: string(),
  ts: string(),
  s: string(),
  sl: string(),
  st: string(),
  v: number(),
})

const MaxMinSchema = object().shape({
  ch: number(),
  mc: number(),
  mcr: number(),
  p: number(),
  pb: number(),
  v: number(),
})

export const CoinsDataResponseSchema = object().shape({
  data: array().of(CoinSchema).required(),
  global: GlobalSchema.required(),
  max: MaxMinSchema.required(),
  min: MaxMinSchema.required(),
  protocols: object().required(),
  categories: object().required(),
  timestamp: number(),
})

export const CoinsStatsDataResponseSchema = object().shape({
  data: array().of(CoinSchema).required(),
  max: MaxMinSchema.required(),
  min: MaxMinSchema.required(),
  timestamp: number(),
})
