import { API_GET_WATCHLIST } from 'src/consts'
import http from 'src/services/http'
import { decorateRequestUrl } from 'src/utils'

export async function getWatchlistRemote() {
  try {
    const url = decorateRequestUrl(API_GET_WATCHLIST)
    return http.get({ url })
  } catch (e) {
    console.log('unable to fetch watchlist:', e)
  }
}

export async function saveWatchlistRemote(params) {
  try {
    const url = decorateRequestUrl(API_GET_WATCHLIST)
    return http.post({ url, params })
  } catch (e) {
    console.log('unable to save watchlist:', e)
  }
}

export async function deleteWatchlistRemote() {
  try {
    const url = decorateRequestUrl(API_GET_WATCHLIST)
    return http.delete({ url })
  } catch (e) {
    console.log('unable to delete watchlist:', e)
  }
}
