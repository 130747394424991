import Router from 'next/router'
import EmailVerification from 'supertokens-auth-react/recipe/emailverification'
import ThirdPartyEmailPassword, {
  Google,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword'
import SessionReact from 'supertokens-auth-react/recipe/session'
import { appInfo } from './appInfo'
import { intl } from 'src/i18n'

export const frontendConfig = () => {
  return {
    appInfo,
    languageTranslations: {
      translations: {
        en: {
          // Might need to fix this part so we can translate everything, haven't found a solution for it yet
          EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE'
          ),
          EMAIL_PASSWORD_SIGN_UP_HEADER_TITLE: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_SIGN_UP_HEADER_TITLE'
          ),
          EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_SIGN_UP_LINK: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_SIGN_UP_LINK'
          ),
          THIRD_PARTY_SIGN_IN_AND_UP_HEADER_TITLE: intl.t(
            'auth.resources.en.translation.THIRD_PARTY_SIGN_IN_AND_UP_HEADER_TITLE'
          ),
          EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN'
          ),
          EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN'
          ),
          EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_SIGN_IN_LINK: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_SIGN_IN_LINK'
          ),
          EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS'
          ),
          EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: intl.t(
            'auth.resources.en.translation.EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN'
          ),
        },
      },
    },
    recipeList: [
      ThirdPartyEmailPassword.init({
        getRedirectionURL: async context => {
          if (context.action === 'SUCCESS') {
            if (context.isNewUser) {
              return '/auth/verify-email'
            }
            if (context.redirectToPath !== undefined) {
              return context.redirectToPath
            }
            return '/'
          }
          return undefined
        },
        override: {
          functions: originalImplementation => {
            return {
              ...originalImplementation,
            }
          },
        },
        style: `
                [data-supertokens~=container] {
                  display: flex;
                  max-width: 400px;
                  margin: 0;
                  padding: 0;
                  background-color: #1D1F1E;
                  box-shadow: none;
                }

                [data-supertokens~=formRow] {
                  padding: 0 0 16px;
              }

              [data-supertokens~=inputContainer] {
               margin: 8px 0 0;
            }

                [data-supertokens~=inputWrapper] {
                    background-color: #000000;
                    border: none;
                    outline: none;
                    height: 40px;
                }
                [data-supertokens~=inputAdormentError] {
                  background-color: #000000;
                }

                [data-supertokens~=showPassword]{
                  background-color: #000000;
                }

                [data-supertokens~=inputWrapper]:focus {
                    background-color: #000000;
                    border: none;
                    outline: none;
                }

                [data-supertokens~=inputWrapper]:focus-within {
                    background-color: #000000;
                    border: none;
                    box-shadow: none;
                    outline: none;
                }

                [data-supertokens~=label] {
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    opacity: 0.8;
                }

                [data-supertokens~=input] {
                    background-color: #000000;
                    color: #FFFFFF;
                    border: none;
                    border-radius: 6px;
                    outline: none;
                    height: 40px;
                }

                [data-supertokens~=button] {
                    background-color: #84C47C;
                    border: none;
                    border-radius: 6px;
                    width: 100%;
                    height: 40px;
                    color: #262626;
                    margin: 24px 0 0;
                    font-size: 16px;
                }

                [data-supertokens~=button]:hover,
                [data-supertokens~=button]:focus {
                  background-color: #FFFFFF;
                }
                
                [data-supertokens~=generalError] {
                  background: none;
                  color: #ED7171;
                }

                [data-supertokens~=providerContainer] {
               
                }

                [data-supertokens~=providerButton] {
                  background-color: #313333;
                  color: #7EC17F;
                  width: 100% !important;
                  margin: 0 !important;
                  max-width: none !important;
                  display: flex;
                  justify-content: center;
                }

                [data-supertokens~=providerButtonLeft] {
                  margin-left: 0px !important;
                }

                [data-supertokens~=thirdPartyEmailPasswordDividerOr] {
                  color: #FFFFFF;
                  opacity: 0.8;
                  margin: 0;
                }

                [data-supertokens~=providerButton]:hover,[data-supertokens~=providerButton]:focus {
                  background-color: black;
                  color: #7EC17F;
                }

                [data-supertokens~=inputError] {
                  border: 1px solid #ED7171;
                  box-shadow: none; 
                  background-color: #000000;
                }
                
                [data-supertokens~=inputErrorMessage] {
                  color: #ED7171; 
                }

                [data-supertokens~=inputAdornment] {
                  background-color: #000000;
                }


                [data-supertokens~=superTokensBranding] {
                    display: none;
                }

                [data-supertokens~=secondaryText] {
                    color: #FFFFFF;
                    opacity: 0.8;
                    font-size: 14px;
                    width: 100%;
                }

                [data-supertokens~=link] {
                    color: #84C47C;
                    font-size: 14px;
                }

                [data-supertokens~=headerTitle] {
                    font-weight: 500;
                    font-size: 22px;
                    color: #FFFFFF;
                    justify-content: center;
                }

                [data-supertokens~=backButton] {
                    display: none;
                }

                [data-supertokens~=row] {
                   width: 100%;
                   padding: 16px;
                }

                [data-supertokens~=divider] {
                   display: none;
                }

                [data-supertokens~="privacyPolicyAndTermsAndConditions"] {
                  width: 100%;
                  max-width: none;
              }

                [data-supertokens~="submitNewPasswordSuccessMessage"] {
                    color: #FFFFFF;
                }
                [data-supertokens~="enterEmailSuccessMessage"] {
                      color: #FFFFFF;
                }
            `,
        signInAndUpFeature: {
          providers: [Google.init()],
          signUpForm: {
            // termsOfServiceLink: "https://coin360.com/about/terms-of-service",
            privacyPolicyLink: 'https://coin360.com/about/privacy',
            formFields: [
              //{
              //  id: 'nickname',
              //  label: 'Username',
              //  placeholder: 'Username',
              //},
              // {
              //   id: 'subscription',
              //   label: '',
              //   optional: true,
              //   inputComponent: ({ name, onChange }) => (
              //     <div
              //       style={{
              //         marginBottom: ' -12px',
              //       }}
              //     >
              //       <input
              //         name={name}
              //         type='checkbox'
              //         defaultChecked={true}
              //         onChange={e => onChange(e.target.checked.toString())}
              //         style={{
              //           accentColor: '#84C47C',
              //         }}
              //       ></input>
              //       <span
              //         style={{
              //           marginLeft: 5,
              //           fontSize: '14px',
              //           color: '#ffffff80',
              //         }}
              //       >
              //         I agree to receive news and updates from Coin360{' '}
              //       </span>
              //     </div>
              //   ),
              // },
            ],
          },
        },
      }),
      EmailVerification.init({
        mode: 'REQUIRED',
        style: `
                [data-supertokens~=container] {
                display: flex;
                width: 320px;
                margin: 0;
                padding: 0;
                    background-color: #2A2D2D;
                }

                [data-supertokens~=primaryText] {
                    color: #FFFFFF;
                }

                [data-supertokens~=secondaryText] {
                    color: #FFFFFF;
                    opacity: 0.8;
                    font-size: 14px;
                }

                [data-supertokens~=link] {
                    color: #84C47C;
                    font-size: 14px;
                }

                [data-supertokens~=headerTitle] {
                    font-weight: 500;
                    font-size: 22px;
                    color: #FFFFFF;
                    justify-content: center;
                }

                [data-supertokens~=row] {
                   width: 100%;
                   padding: 16px;
                }

                [data-supertokens~=button] {
                    background-color: #84C47C;
                    border: none;
                    border-radius: 6px;
                    width: 100%;
                    height: 40px;
                    color: black;
                }
            `,
      }),
      SessionReact.init(),
    ],
    windowHandler: oI => {
      return {
        ...oI,
        location: {
          ...oI.location,
          setHref: href => {
            Router.push(href)
          },
        },
      }
    },
  }
}
