import { useMediaQueries } from '@react-hook/media-query'
// import { breakpoints } from '../styles/utils.scss'

// TODO import from utils
const breakpoints = {
  sm: '(max-width: 640px)',
  md: '(max-width: 768px)',
  lg: '(max-width: 1024px)',
  xl: '(max-width: 1280px)',
  '2xl': '(max-width: 1536px)',
}

type MediaQueries = keyof typeof breakpoints

export function useBreackpoints<P extends MediaQueries[]>(
  ...params: P
): {
  [key in P[number]]: boolean
} {
  const queryMap = params.reduce((acu, item) => {
    acu[item] = breakpoints[item]
    return acu
  }, {})

  const { matches } = useMediaQueries(queryMap)
  return matches as { [key in P[number]]: boolean }
}
