import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const useWidth = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isResized, setIsResized] = useState()
  const [getWidth, setGetWidth] = useState(null)
  const [getWidthWindow, setGetWidthWindow] = useState(null)
  const [isLandscape, setIsLandscape] = useState(false)

  const handleResize = useDebouncedCallback(e => setIsResized(e), 200)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      setIsMobile(window.innerWidth <= 1024)
      setGetWidth(screen.width)
      setGetWidthWindow(window.innerWidth)
      // test on iphones
      setIsLandscape(
        window.matchMedia('(orientation: landscape)').matches &&
          window.innerWidth <= 1024
      )
    }
  }, [isResized, handleResize])

  return { isMobile, getWidth, isLandscape, getWidthWindow }
}

export default useWidth
