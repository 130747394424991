import { createSelector } from 'reselect'
import { getFilters } from './filters'

export const getCoinsFilters = createSelector(
  getFilters,
  filters => filters.coins
)

export const getCoinsDependsOn = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.dependsOn
)

export const getCoinsRange = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.range
)

export const getCoinsPeriod = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.period
)

export const getCoinsActivePeriod = createSelector(
  getCoinsPeriod,
  coinsPeriod => coinsPeriod.active
)

export const getCoinsDisplay = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.display
)

export const getCoinsExceptions = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.exceptions
)

export const getCoinsGroup = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.group
)

export const getCoinsTop = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.top
)

export const getCoinsTopParsed = createSelector(
  getCoinsFilters,
  coinsFilters => {
    const value = coinsFilters.top

    const gainersRegex = /gainers(\d+)/
    const losersRegex = /losers(\d+)/

    if (gainersRegex.test(value)) {
      return {
        type: 'gainers',
        value: parseInt(gainersRegex.exec(value)[1]),
      }
    } else if (losersRegex.test(value)) {
      return {
        type: 'losers',
        value: parseInt(losersRegex.exec(value)[1]),
      }
    } else {
      return {
        type: 'all',
        value: undefined,
      }
    }
  }
)

export const getCoinsExceptionsDict = createSelector(getCoinsExceptions, e =>
  (e || []).reduce((acc, item) => {
    acc[item] = item
    return acc
  }, {})
)

export const getCoinsPeriodSelector = ({ filters }) => filters.coins.period

export const getCoinsRanking = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.ranking
)

export const getCoinsTag = createSelector(
  getCoinsFilters,
  coinsFilters => coinsFilters.tag
)
