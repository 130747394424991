import subMonths from 'date-fns/subMonths'
import subDays from 'date-fns/subDays'
import _isValid from 'date-fns/isValid'
import _format from 'date-fns/format'

const timeFormatLong = "'UTC: 'HH:mm, d LLL yyyy"

export function getUTCDate(dateString = Date.now()) {
  const date = new Date(dateString)
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export function getSubtractMonths(date, amount) {
  return subMonths(date, amount)
}

export function getSubtractDays(date, amount) {
  return subDays(date, amount)
}

export function isValidCheck(date) {
  return _isValid(date)
}

export function formatDate(date, format) {
  return _format(getUTCDate(date), format)
}

export function formatDateToTimestamp(date) {
  return new Date(date).getTime()
}

export function getLongTime() {
  return _format(getUTCDate(), timeFormatLong)
}
