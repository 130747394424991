import { HYDRATE } from 'next-redux-wrapper'
import { GET_MAPDATA, GET_MAPDATA_ERROR } from 'src/redux/actions'

const initialState = {
  state: {
    isRequestProcess: false,
    isRequestSuccess: true,
    isRequestFailure: false,
  },
  coins: {
    data: [],
    dict: {},
    min: {
      change: 0,
      marketCap: 0,
      price: 0,
      priceBtc: 0,
      volume: 0,
    },
    max: {
      change: 0,
      marketCap: 0,
      price: 0,
      priceBtc: 0,
      volume: 0,
    },
    global: {
      changeMarketCap: 0,
      changeVolume: 0,
      marketCap: 0,
      volume: 0,
    },
    categories: {},
    protocols: {},
    timestamp: 0,
  },
  exchanges: {
    data: [],
    dict: {},
    min: {
      change: 0,
      changeReported: 0,
      scaledVolume: 0,
      scaledVolumeReported: 0,
      volume: 0,
      volumeReported: 0,
    },
    max: {
      change: 0,
      changeReported: 0,
      scaledVolume: 0,
      scaledVolumeReported: 0,
      volume: 0,
      volumeReported: 0,
    },
    global: {
      changeMarketCap: 0,
      changeVolume: 0,
      marketCap: 0,
      volume: 0,
    },
    timestamp: 0,
  },
}

export const mapdata = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.mapdata,
      }
    case GET_MAPDATA:
      return {
        ...state,
        ...action.payload,
        state: {
          isRequestProcess: false,
          isRequestSuccess: true,
          isRequestFailure: false,
        },
      }
    case GET_MAPDATA_ERROR:
      return {
        ...state,
        state: {
          isRequestProcess: false,
          isRequestSuccess: false,
          isRequestFailure: true,
        },
      }
    default:
      return { ...state }
  }
}

export default mapdata
