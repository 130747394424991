export const SET_SHARED_CURRENCY = 'SET_SHARED_CURRENCY'
export const RESET_SHARED_FILTERS = 'RESET_SHARED_FILTERS'

export const setSharedCurrencyAction = payload => ({
  type: SET_SHARED_CURRENCY,
  payload,
})

export const resetSharedFiltersAction = () => ({
  type: RESET_SHARED_FILTERS,
})
