import { setAuthenticated, setUnauthenticated } from '../actions/auth'
import Session from 'supertokens-auth-react/recipe/session'

export const checkAuthStatus = () => async dispatch => {
  const doesSessionExist = await Session.doesSessionExist()
  if (doesSessionExist) {
    dispatch(setAuthenticated())
  } else {
    dispatch(setUnauthenticated())
  }
}

export const logOut = () => async dispatch => {
  await Session.signOut()
  dispatch(setUnauthenticated())
}
