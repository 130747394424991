import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
// import { CountryFlag } from 'react-country-flag'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './styles.module.scss'
import { saveUserDetails } from 'src/services/userServices'
import { UsernameSuccess } from '../UsernameSuccess'

export const ChangeUserFields = ({ onSuccess, title, type, fieldToUpdate }) => {
  const [newValue, setNewValue] = useState('')
  const [error, setError] = useState('')
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false) // New state to track if update is successful
  const options = useMemo(() => countryList().getData(), [])

  const handleSuccessClose = () => {
    setIsUpdateSuccess(false) // Reset the state to hide the UsernameSuccess component
  }
  const handleSelectChange = value => {
    setNewValue(value.label)
  }

  const maxLength = fieldToUpdate === 'fullName' ? 25 : 10

  const handleSubmit = async e => {
    e.preventDefault()

    if (fieldToUpdate === 'fullName') {
      if (newValue.length > 25) {
        setError(`${title} cannot exceed 25 characters.`)
        return
      }
    } else if (fieldToUpdate === 'nickname' && newValue.length > 10) {
      setError(`${title} cannot exceed 10 characters.`)
      return
    }

    try {
      const result = await saveUserDetails({
        [fieldToUpdate]: newValue,
      })
      if (result) {
        setIsUpdateSuccess(true)
        onSuccess(newValue)
      } else {
        setError('An unexpected error occurred. Please try again.')
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(`${title} already exists. Please try a different one.`)
      } else {
        setError(`Failed to update ${title}. Please try again.`)
      }
    }
  }

  if (isUpdateSuccess) {
    return (
      <UsernameSuccess
        onClose={() => {
          onSuccess()
          handleSuccessClose()
        }}
      />
    )
  }

  if (isUpdateSuccess) {
    return (
      <UsernameSuccess
        onClose={() => {
          onSuccess()
          handleSuccessClose()
        }}
      />
    )
  }

  // If the update is not successful yet, render the form
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.title}>Change {title}</h2>
      <p className={styles.text}>
        You are about to change your {title}. Please confirm the changes.
      </p>
      <div className={styles.formGroup}>
        <label className={styles.label}>New {title}</label>
        {fieldToUpdate === 'country' ? ( // If fieldToUpdate is "country", render Select
          <Select
            options={options}
            value={newValue}
            placeholder={newValue ? newValue : 'Select country'}
            onChange={handleSelectChange}
            className={styles.customSelect}
            classNamePrefix={'custom-select'}
          />
        ) : fieldToUpdate === 'phone' ? (
          <div className={styles.phoneContainer}>
            <PhoneInput
              country={'us'} // Ange default land (t.ex. 'us' för USA)
              value={newValue}
              onChange={value => setNewValue(value)}
              inputClass={styles.phoneInput}
              containerClass={styles.phoneInputContainer}
              buttonClass={styles.phoneInputButton}
              dropdownClass={styles.dropdownFlags}
              inputProps={{
                maxLength: 20,
                required: true,
              }}
            />
          </div>
        ) : (
          <input
            className={styles.input}
            type={type}
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            required={true}
            maxLength={maxLength}
          />
        )}
      </div>
      <button type={'submit'} className={styles.button}>
        Confirm
      </button>
      {error && <div className={styles.error}>{error}</div>}
      {/* Display the error message if there is one */}
    </form>
  )
}
