import { API_GET_MAPDATA, API_GET_EXCHANGES_MAPDATA } from 'src/consts'
import { prepareExchangeData, prepareMapData } from 'src/utils/data'

export const ENTITY = {
  COIN: 'coin',
  EXCHANGE: 'exchange',
  NFT: 'nft',
} as const

export const WIDGET_COINS_DEFAULT_CONFIG = {
  width: '100%',
  height: 252,
  primaryCurrency: 'USD',
  primaryTickerSymbol: true,
  secondaryCurrency: 'BTC',
  showRank: true,
  showMarketCap: true,
  showVolume: true,
  brightVersion: false,
}

export const ENTITY_API_ROUTES = {
  [ENTITY.COIN]: API_GET_MAPDATA,
  [ENTITY.EXCHANGE]: API_GET_EXCHANGES_MAPDATA,
}

export const ENTITY_ADAPTERS = {
  [ENTITY.COIN]: prepareMapData,
  [ENTITY.EXCHANGE]: prepareExchangeData,
}

const ENTITY_MAP_RANGES = {
  [ENTITY.COIN]: [1e-3, 100, 350],
  [ENTITY.EXCHANGE]: [1e-3, 100, 500],
}
export const getEntityMapRange = entity =>
  ENTITY_MAP_RANGES[entity] || ENTITY_MAP_RANGES[ENTITY.COIN]

export const chooseByEntity = (entity, coinsOption, exchangesOption) =>
  ({
    [ENTITY.COIN]: coinsOption,
    [ENTITY.EXCHANGE]: exchangesOption,
  }[entity])

export const getEntityAdapter = entity =>
  ENTITY_ADAPTERS[entity] || (data => data)
export const getEntityCollectionName = entity =>
  ENTITY_COLLECTIONS[entity] || ''
export const mapEntityCollectionToEntity = col =>
  ({
    coins: ENTITY.COIN,
    exchanges: ENTITY.EXCHANGE,
  }[col])

const ENTITY_COLLECTIONS = {
  [ENTITY.COIN]: 'coins',
  [ENTITY.EXCHANGE]: 'exchanges',
}
