import { getDirectionByChange } from 'src/utils/common'
import { formatChangeValue } from 'src/utils/number/number'
import { formatValueWithCurrencyOB } from 'src/utils/currency'

const formatGlobalMarketCap = (globals, currency) =>
  formatValueWithCurrencyOB(globals.marketCap, currency)

const formatGlobalMarketCapLong = (globals, currency) =>
  formatValueWithCurrencyOB(globals.marketCap, currency, true, true)

const formatGlobalMarketCapChange = globals =>
  `${formatChangeValue(globals.changeMarketCap, false)}%`

const formatGlobalVolume = (globals, currency) =>
  formatValueWithCurrencyOB(globals.volume, currency)

const formatGlobalVolumeLong = (globals, currency) =>
  formatValueWithCurrencyOB(globals.volume, currency, true, true)

const formatGlobalVolumeChange = globals =>
  `${formatChangeValue(globals.changeVolume, false)}%`

const formatPeriod = period => {
  if (!(period && typeof period === 'string')) {
    return ''
  }
  switch (period.toLowerCase()) {
    case '1h':
    case '24h':
    case '7d':
    case '30d':
      return period
    case 'custom':
      return ''
    default:
      return ''
  }
}

const formatVolumeDirection = globals =>
  getDirectionByChange(globals.changeVolume || 0)

const formatMCapDirection = globals =>
  getDirectionByChange(globals.changeMarketCap || 0)

const formatDominance = globals =>
  `${formatChangeValue(globals.dominanceBTC, false)}%`

export const formatGlobals = (globals, currency, period) => ({
  globalMarketCap: formatGlobalMarketCap(globals, currency),
  globalMarketCapLong: formatGlobalMarketCapLong(globals, currency),
  globalMarketCapChange: formatGlobalMarketCapChange(globals),
  globalVolume: formatGlobalVolume(globals, currency),
  globalVolumeLong: formatGlobalVolumeLong(globals, currency),
  globalVolumeChange: formatGlobalVolumeChange(globals),
  period: formatPeriod(period),
  volumeDirection: formatVolumeDirection(globals),
  mCapDirection: formatMCapDirection(globals),
  dominance: formatDominance(globals),
})
