export const GET_MARKETS_DATA = 'GET_MARKETS_DATA'
export const GET_MARKETS_DATA_SUCCESS = 'GET_MARKETS_DATA_SUCCESS'
export const GET_MARKETS_DATA_FAILURE = 'GET_MARKETS_DATA_FAILURE'
export const SET_MARKETS_CURRENCY = 'SET_MARKETS_CURRENCY'

export const getMarketsDataAction = payload => ({
  type: GET_MARKETS_DATA,
  payload,
})

export const getMarketsDataSuccessAction = payload => ({
  type: GET_MARKETS_DATA_SUCCESS,
  payload,
})

export const getMarketsDataFailureAction = payload => ({
  type: GET_MARKETS_DATA_FAILURE,
  payload,
})

export const setMarketsCurrencyAction = payload => ({
  type: SET_MARKETS_CURRENCY,
  payload,
})
