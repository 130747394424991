import * as d3Scale from 'd3-scale'
import * as d3Hierarchy from 'd3-hierarchy'

export const SCALING_SMOOTH = 'smooth'
export const SCALING_CONTRAST = 'contrast'

export default class TreePack {
  constructor({
    data,
    baseValue,
    range,
    diffLimit = -1,
    scaling = SCALING_SMOOTH,
  }) {
    this.calcWeights(data, baseValue, diffLimit)

    const start = 0
    const stop = data.maxValues.weight - data.minValues.weight
    const domain =
      scaling === SCALING_CONTRAST
        ? [start, stop / 3, stop]
        : [start, stop / 100, stop / 10, stop]
    const scale = d3Scale.scaleLinear().domain(domain).range(range)

    const hierarchy = d3Hierarchy
      .hierarchy(data)
      .sum(item => scale(item.weight - data.minValues.weight))

    hierarchy.sort((a, b) => b.height - a.height || b.value - a.value)

    this.treeRoot = hierarchy
    this.protectedLeaves = hierarchy.leaves()
    this.protectedGroups = []
    hierarchy.each(item => {
      if (item.height === 1) {
        this.protectedGroups.push(item)
      }
    })
  }

  get leaves() {
    return this.protectedLeaves
  }

  get groups() {
    return this.protectedGroups
  }

  calcWeights(data, baseValue, diffLimit) {
    if (!data.children) return
    data.children.forEach(item => this.calcWeights(item, baseValue, diffLimit))

    if (diffLimit !== -1) {
      const total = data.children.reduce((a, item) => a + item[baseValue], 0)

      data.maxValues.weight = (data.maxValues[baseValue] / total) * 100
      data.minValues.weight = (data.minValues[baseValue] / total) * 100
      data.children.forEach(
        item =>
          (item.weight =
            item[baseValue] !== 0 ? (item[baseValue] / total) * 100 : 1)
      )

      for (let i = 0; i < data.children.length - 1; i++) {
        let tailTotal = 0
        for (let j = i + 1; j < data.children.length; j++) {
          tailTotal += data.children[j].weight
        }

        const diff = data.children[i].weight - tailTotal
        if (diff <= diffLimit) {
          continue
        }

        const delta = (diff - diffLimit) / 2
        data.children[i].weight -= delta

        for (let j = i + 1; j < data.children.length; j++) {
          data.children[j].weight *= (tailTotal + delta) / tailTotal
        }
      }
    } else {
      data.maxValues.weight = data.maxValues[baseValue]
      data.minValues.weight = data.minValues[baseValue]
      data.children.forEach(item => (item.weight = item[baseValue]))
    }
  }
}
