import { array, number, object, string } from 'yup'
import { GlobalSchema } from './common'

const ExchangeSchema = object().shape({
  change: number(),
  changeReported: number(),
  coins: number(),
  coreType: string(),
  country: string(),
  deposit: string(),
  feeType: string(),
  fees: array().of(string()),
  fiatDeposit: string(),
  launched: string(),
  marginTrading: string(),
  name: string(),
  pairs: number(),
  reddit: number(),
  scaledVolume: number(),
  scaledVolumeReported: number(),
  slug: string(),
  trustPilotScore: number(),
  trustPilotTotal: number(),
  twitter: number(),
  type: string(),
  visits: number(),
  volume: number(),
  volumeReported: number(),
})

const MaxMinSchema = object().shape({
  change: number(),
  changeReported: number(),
  pairs: number(),
  scaledVolume: number(),
  scaledVolumeReported: number(),
  visits: number(),
  volume: number(),
  volumeReported: number(),
})

export const ExchangesDataResponseSchema = object().shape({
  data: array().of(ExchangeSchema).required(),
  global: GlobalSchema.required(),
  max: MaxMinSchema.required(),
  min: MaxMinSchema.required(),
  timestamp: number(),
})
