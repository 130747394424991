import * as d3Hierarchy from 'd3-hierarchy'
import { BLOCKS } from 'src/consts'

export const TreeMapHelper = ({ width, height, isMobile, treeRoot }) =>
  d3Hierarchy
    .treemap()
    .tile(d3Hierarchy.treemapBinary)
    .size([width, height])
    .padding(d => {
      switch (d.height) {
        case 1:
          return isMobile ? 0.1 : 0.4
        case 2:
          return isMobile ? 1 : 4
        default:
          return 0
      }
    })
    .paddingTop(d => {
      const width = d.x1 - d.x0
      const height = d.y1 - d.y0

      if (d.children && d.height !== 1) {
        return 0
      }

      const full =
        width >= BLOCKS.MIN_GROUP_HEADER_WIDTH &&
        height >= BLOCKS.MIN_GROUP_HEADER_HEIGHT

      Object.assign(d, { full })
      return full ? 20 : 1
    })
    .round(false)(treeRoot)
